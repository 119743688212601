import { APIprovider } from './Request-service';

export class APIWeekOfferProvider {
  private apiProvider = new APIprovider();

  fetchWeekOffer(): Promise<Response> {
    return this.apiProvider.get('/weekOffer');
  }

  deleteWeekOffer(): Promise<Response> {
    return this.apiProvider.get('/deleteWeekOffer');
  }

  uploadWeekOffer(
    imageData: string,
    create_at: string,
    remove_at: string
  ): Promise<Response> {
    const uploadData = {
      imageData: imageData,
      create_at: create_at,
      remove_at: remove_at,
    };

    return this.apiProvider.post(
      '/uploadWeekOffer',
      JSON.stringify(uploadData)
    );
  }
}
