import React from 'react';
import Slider from 'react-slick';
import aperol from '../assets/images/sponsor-images/aperol.png';
import birel from '../assets/images/sponsor-images/birell.png';
import corona from '../assets/images/sponsor-images/Corona-Extr.png';
import gambrinus from '../assets/images/sponsor-images/gambrinus.png';
import havana from '../assets/images/sponsor-images/havana.png';
import jagermeister from '../assets/images/sponsor-images/jagermeister.png';
import metaxa from '../assets/images/sponsor-images/aperol.png';
import pilsner from '../assets/images/sponsor-images/pilsner.png';
export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 250,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Slider className='mt-5' {...settings}>
      <div className='mt-3 text-center'>
        <img src={aperol} className='mx-auto' width='200' alt='aperol' />
      </div>
      <div className='mt-3 text-center'>
        <img src={birel} className='mx-auto' width='200' alt='aperol' />
      </div>
      <div className='mt-3 text-center'>
        <img src={corona} className='mx-auto' width='200' alt='aperol' />
      </div>
      <div className='mt-3 text-center'>
        <img src={gambrinus} className='mx-auto' width='200' alt='aperol' />
      </div>
      <div className='mt-3 text-center'>
        <img src={havana} className='mx-auto' width='200' alt='aperol' />
      </div>
      <div className='mt-3 text-center'>
        <img src={jagermeister} className='mx-auto' width='200' alt='aperol' />
      </div>
      <div className='mt-3 text-center'>
        <img src={metaxa} className='mx-auto' width='200' alt='aperol' />
      </div>
      <div className='mt-3 text-center'>
        <img src={pilsner} className='mx-auto' width='200' alt='aperol' />
      </div>
    </Slider>
  );
}
