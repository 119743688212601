import './Actions.css';
import Header from '../../global-components/Header';
import Footer from '../../global-components/Footer';

export default function Actions() {
    return (
        <>
            <Header position='top-fixed' />
            <div id="actions">
            </div>
            <Footer />

        </>

    );
}