import { useEffect } from 'react';
import $ from 'jquery';
import React from 'react';
export default function PageHeader(props: {
  customID: string | undefined;
  backgroundImage: string;
  title:
    | string
    | number
    | boolean
    | React.ReactFragment
    | React.ReactPortal
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | null
    | undefined;
}) {
  useEffect(() => {
    $('#' + props.customID).css(
      'backgroundImage',
      'url("' + props.backgroundImage + '")'
    );
  }, []);

  return (
    <>
      <div id={props.customID} className='page-header container-fluid'>
        <div className='row h-100 title-row-header'>
          <div className='col text-center mx-auto'>
            <h1 className='mx-auto '>{props.title}</h1>
          </div>
        </div>
      </div>
    </>
  );
}
