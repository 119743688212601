import './about.css';
import Header from '../../global-components/Header';
import Footer from '../../global-components/Footer';
export default function About(props: any) {
  return (
    <div id='about'>
      <Header position='top-fixed' />

      <Footer />
    </div>
  );
}
