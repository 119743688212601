import React from 'react';
import logo from './logo.svg';
import './App.css';
import { useRoutes, Navigate } from 'react-router-dom';

import Home from './pages/home/Home';
import About from './pages/about/About';
import Foods from './pages/foods/Foods';
import Actions from './pages/actions/Actions';
import Drinks from './pages/drinks/Drinks';
import Contact from './pages/contact/Contact';
import Login from './pages/login/Login';
import Administration from './pages/administration/Administration';
import Coupons from './pages/coupons/Coupons';

function App() {
  let element = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/foods', element: <Foods /> },
    { path: '/drinks', element: <Drinks /> },
    { path: '/actions', element: <Actions /> },
    { path: '/contact', element: <Contact /> },
    { path: '/coupons', element: <Coupons /> },
    { path: '/administration/login', element: <Login /> },
    { path: '/administration', element: <Administration /> },
    { path: '/admin', element: <Navigate to='/administration/login' /> },
    { path: '/login', element: <Navigate to='/administration/login' /> },
    { path: '/log', element: <Navigate to='/administration/login' /> },
    { path: '/sign', element: <Navigate to='/administration/login' /> },
    { path: '/prihlasit', element: <Navigate to='/administration/login' /> },
    { path: '/prihlasitse', element: <Navigate to='/administration/login' /> },
  ]);

  return element;
}

export default App;
