import DameJidloIcon from '../assets/logos/dame-jidlo-icon';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import Logo from '../assets/logos/logo';

export default function Footer() {
  return (
    <>
      <footer id='footer' className='footer-16371'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-9 text-center'>
              <div className='footer-site-logo mb-4'>
                <Logo />
              </div>
              <ul className='list-unstyled nav-links mb-5'>
                <li>
                  <a href='/'>Domů</a>
                </li>
                <li>
                  <a href='/drinks'>Nápoje</a>
                </li>
                <li>
                  <a href='/foods'>Jídla</a>
                </li>
                <li>
                  <a href='coupons'>Kupóny</a>
                </li>
                <li>
                  <a href='/actions'>Akce</a>
                </li>
                <li>
                  <a href='/contact'>Kontakt</a>
                </li>
              </ul>

              <div className='social mb-4'>
                <h3>Naše sociální sítě</h3>
                <ul className='list-unstyled'>
                  <li className='in'>
                    <a href='https://www.instagram.com/sokolovnarestaurant/?hl=cs'>
                      <InstagramIcon />
                    </a>
                  </li>
                  <li className='fb'>
                    <a href='https://www.facebook.com/SokolovnaProbostov/'>
                      <FacebookIcon />
                    </a>
                  </li>
                  <li className='tw'>
                    <a href='https://www.damejidlo.cz/restaurant/t4gv/restaurace-sokolovna-t4gv'>
                      <DameJidloIcon />
                    </a>
                  </li>
                </ul>
              </div>

              <div className='copyright'>
                <p className='mb-0'>
                  <small>&copy; Sokolovna 2022. Všechna práva vyhrazena.</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
