import './Administration.css';
import Logo from '../../assets/logos/logo';

import $ from 'jquery';
import { useState, useEffect } from 'react';
import { APIWeekOfferProvider } from '../../providers/API-providers/api-week-offer-provider';
import { UserStorage } from '../../providers/local-providers/UserStorage';
import { useNavigate } from 'react-router-dom';
import { formatRelative } from 'date-fns';
import { UserManager } from '../../providers/local-providers/UserManager';
import CircularProgress from '@mui/material/CircularProgress';
import { cs, el } from 'date-fns/locale';
import SpecialOfferAdminTile from './components/SpecialOfferTile';
import { APISpecialOfferProvider } from '../../providers/API-providers/api-special-offer-provider';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const apiWeekOfferProvider: APIWeekOfferProvider = new APIWeekOfferProvider();
const apiSpecialOfferProvider: APISpecialOfferProvider = new APISpecialOfferProvider();
const userStorage = new UserStorage();
const userManager: UserManager = new UserManager();

var isDataFilled: Boolean = false;
var isSpecialOfferFilled: Boolean = false;

export default function Administration() {
  const [weekOfferData, setWeekOfferData] = useState({
    imageData: '',
    createAt: new Date(),
    removeAt: new Date(),
  });

  const [specialOfferData, setSpecialOfferData] = useState([
    {
      id: 0,
      title: '',
      price: '',
      description: '',
      imageData: '',
    },
  ]);
  const [isShowLoader, setIsShowLoader] = useState(true);
  const navigate = useNavigate();
  const [uploadImageData, setUploadImageData] = useState('');
  const [uploadCreateAt, setUploadCreateAt] = useState('');
  const [uploadRemoveAt, setUploadRemoveAt] = useState('');

  const [specialOfferImage, setSpecialOfferImage] = useState('');
  const [specialOfferTitle, setSpecialOfferTitle] = useState('');
  const [specialOfferPrice, setSpecialOfferPrice] = useState('');
  const [specialOfferDescription, setSpecialOfferDescription] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    if (userStorage.getUsertoken().toString() == '') {
      navigate('/login');
    }
    fetchWeekOffer();
    fetchSpecialOffer();
    setInterval(() => {
      fetchWeekOffer();
      fetchSpecialOffer();
    }, 15000);

    $(function () {
      $('.sidebar-link').click(function () {
        $('.sidebar-link').removeClass('is-active');
        $(this).addClass('is-active');
      });
    });

    if (window != undefined) {
      $(window)
        .resize(function () {
          if (window.innerWidth > 1090) {
            $('.sidebar').removeClass('collapse');
          } else {
            $('.sidebar').addClass('collapse');
          }
        })
        .resize();
    }

    $(function () {
      $('.logo, .logo-expand, .discover').on('click', function (e) {
        $('.main-container').removeClass('show');
        $('.main-container').scrollTop(0);
      });
      $('.trending, .video').on('click', function (e) {
        $('.main-container').addClass('show');
        $('.main-container').scrollTop(0);
        $('.sidebar-link').removeClass('is-active');
        $('.trending').addClass('is-active');
      });
    });
  }, []);

  const fetchWeekOffer = () => {
    apiWeekOfferProvider
      .fetchWeekOffer()
      .then((response) => {
        if (response.status == 204) {
          return 204;
        }
        if (response.ok) {
          return response.json();
        } else {
          //toast error
          return false;
        }
      })
      .then((data) => {
        if (data == 204) {
          clearData();
          isDataFilled = false;
        } else {
          setWeekOfferData({
            imageData: data.weekOffer.image_data,
            createAt: new Date(data.weekOffer.create_at),
            removeAt: new Date(data.weekOffer.remove_at),
          });
          isDataFilled = true;
        }
        setIsShowLoader(false);
      })
      .catch((error) => {
        //toast error
        setIsShowLoader(false);
        clearData();
        console.error(error);
      });
  };

  const fetchSpecialOffer = () => {
    apiSpecialOfferProvider
      .fetchSpecialOffer()
      .then((response) => {
        if (response.status == 204) {
          return 204;
        }
        if (response.ok) {
          return response.json();
        } else {
          //toast error
          return false;
        }
      })
      .then((data: any) => {
        if (data == 204) {
          clearSpecialOffer();
        } else {
          setSpecialOfferData([]);
          setSpecialOfferData((specialOfferData) =>
            data.specialOfferArray.map((element: any) => {
              return {
                id: element.id,
                title: element.name,
                price: element.price,
                description: element.description,
                imageData: element.image_data,
              };
            })
          );
        }
      })
      .catch((error) => {
        setIsShowLoader(false);
        clearSpecialOffer();
        console.error(error);
      });
  };

  const clearSpecialOffer = () => {
    isSpecialOfferFilled = false;
    setIsShowLoader(true);
    setSpecialOfferData((specialOfferData) => [
      {
        id: 0,
        title: '',
        price: '',
        description: '',
        imageData: '',
      },
    ]);
    setIsShowLoader(false);
  };

  const clearData = () => {
    isDataFilled = false;
    setIsShowLoader(true);
    setWeekOfferData({
      imageData: '',
      createAt: new Date(),
      removeAt: new Date(),
    });
    setIsShowLoader(false);
  };

  const deleteWeekOffer = () => {
    setIsShowLoader(true);

    apiWeekOfferProvider.deleteWeekOffer().then((response) => {
      if (response.status == 202) {
        //toast success
        fetchWeekOffer();
        isDataFilled = false;
      } else {
        //toast error
        setIsShowLoader(false);
      }
    });
  };

  const getMainBlog = () => {
    if (isShowLoader) {
      return (
        <div className='main-blogs'>
          <CircularProgress />
        </div>
      );
    } else {
      if (isDataFilled) {
        return (
          <div className='main-blogs'>
            <div className='main-blog anim'>
              <img
                className='main-blog-image'
                src={weekOfferData.imageData}
                alt=''
              />
              <div className='mt-5' id='dates'>
                <h3>
                  <b>Počáteční datum: </b>
                  {formatRelative(weekOfferData.createAt, new Date(), {
                    locale: cs,
                  }) +
                    ',' +
                    weekOfferData.createAt}
                </h3>
                <h3>
                  <b>Konečné datum: </b>
                  {formatRelative(weekOfferData.removeAt, new Date(), {
                    locale: cs,
                  }) +
                    ', ' +
                    weekOfferData.removeAt}
                </h3>
              </div>
            </div>
            <div className='main-blog anim'>
              <div className='w-100 mx-auto'>
                <button
                  onClick={() => {
                    deleteWeekOffer();
                  }}
                  className='main-blog__title w-100 btn btn-outline-danger'
                >
                  Vymazat menu
                </button>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className='main-blogs h-100 w-100'>
            <div className='main-blog h-100 w-100 mx-auto anim'>
              <div className='w-100 mx-auto'>
                <button
                  className='main-blog__title w-100 btn btn-outline-light'
                  data-toggle='modal'
                  data-target='#createModal'
                >
                  Nahrát menu
                </button>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const getSpecialOfferBlog = () => {
    if (isShowLoader) {
      return (
        <div className='main-blogs2'>
          <CircularProgress />
        </div>
      );
    } else {
      return specialOfferData.map((element, key) => {
        return (
          <SpecialOfferAdminTile
            itemPrice={element.price}
            itemName={element.title}
            description={element.description}
            imageSrc={element.imageData}
            id={element.id}
            key={key}
          />
        );
      });
    }
  };

  const logOutUser = () => {
    userManager.logout();
    navigate('/login');
  };

  const uploadWeekOffer = () => {
    setIsShowLoader(true);
    if (uploadImageData != '' || uploadCreateAt != '' || uploadRemoveAt != '') {
      setErrorMessage('');
      apiWeekOfferProvider
        .uploadWeekOffer(uploadImageData, uploadCreateAt, uploadRemoveAt)
        .then(() => {
          $('#close-button')?.trigger('click');
          setUploadCreateAt('');
          setUploadImageData('');
          setUploadRemoveAt('');
          fetchWeekOffer();
        })
        .catch((error) => {
          setErrorMessage(error);
          setUploadCreateAt('');
          setUploadImageData('');
          setUploadRemoveAt('');
        });
    } else {
      setErrorMessage('Jeden z parametrů chybí.');
    }
  };

  const uploadSpecialOffer = () => {
    setIsShowLoader(true);
    if (specialOfferTitle != '') {
      setErrorMessage('');
      apiSpecialOfferProvider
        .uploadSpecialOffer(
          specialOfferTitle,
          specialOfferPrice,
          specialOfferDescription,
          specialOfferImage
        )
        .then(() => {
          $('#close2-button')?.trigger('click');
          setSpecialOfferDescription('');
          setSpecialOfferImage('');
          setSpecialOfferPrice('');
          setSpecialOfferTitle('');
          fetchSpecialOffer();
        })
        .catch((error) => {
          setErrorMessage(error);
          setSpecialOfferDescription('');
          setSpecialOfferImage('');
          setSpecialOfferPrice('');
          setSpecialOfferTitle('');
        });
    } else {
      setErrorMessage('Jeden z parametrů chybí.');
    }
  };

  const convertUploadImageData = (event: any) => {
    var file = event.target.files[0];
    var reader = new FileReader();

    reader.onload = function (event) {
      // The file's text will be printed here¨)
      if (event?.target?.result != undefined)
        setUploadImageData(event?.target?.result?.toString());
    };

    reader.readAsDataURL(file);
  };

  const convertImageDataSpecialOffer = (event: any) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      // The file's text will be printed here¨)
      if (event?.target?.result != undefined)
        setSpecialOfferImage(event?.target?.result?.toString());
    };

    reader.readAsDataURL(file);
  };

  return (
    <div id='admin-site'>
      <div
        className='modal show fade'
        id='createModal'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content custom-modal'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Nahrát menu
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
                id='close-button'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div id='form-control' className='w-100'>
                <div className='form-control-item w-100'>
                  <label
                    htmlFor='file-input'
                    className='btn btn-outline-light w-100 select-file-btn'
                  >
                    <UploadFileIcon />
                  </label>
                  <input
                    type='file'
                    id='file-input'
                    onChange={convertUploadImageData}
                    className='d-none'
                  />
                </div>
                <div className='form-control-item mt-5 w-100'>
                  Zvolit datum nahrání:
                  <input
                    type='datetime-local'
                    id='date-upload-input'
                    onChange={(e) => setUploadCreateAt(e.target.value)}
                    value={uploadCreateAt}
                    className=''
                  />
                </div>
                <div className='form-control-item mt-5 w-100'>
                  Zvolit datum vymazání:
                  <input
                    type='datetime-local'
                    id='date-delete-input'
                    onChange={(e) => setUploadRemoveAt(e.target.value)}
                    value={uploadRemoveAt}
                    className=''
                  />
                </div>
                <div className='error-text w-100 text-center'>
                  <p>{errorMessage}</p>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => uploadWeekOffer()}
              >
                Nahrát
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className='modal show fade'
        id='specialModal'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content custom-modal'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel2'>
                Vytvořit speciální nabídku
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
                id='close2-button'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div id='form-control2' className='w-100'>
                <div className='form-control-item2 w-100'>
                  <label
                    htmlFor='file-input2'
                    className='btn btn-outline-light w-100 select-file-btn'
                  >
                    <UploadFileIcon />
                  </label>
                  <input
                    type='file'
                    id='file-input2'
                    onChange={convertImageDataSpecialOffer}
                    className='d-none'
                  />
                </div>
                <div className='form-control-item2 w-100'>
                  <input
                    type='text'
                    id='title-input'
                    placeholder='Název'
                    className=''
                    value={specialOfferTitle}
                    onChange={(e) => setSpecialOfferTitle(e.target.value)}
                  />
                </div>
                <div className='form-control-item2 w-100'>
                  <input
                    type='text'
                    id='price-input'
                    placeholder='cena(volitelné)'
                    className=''
                    value={specialOfferPrice}
                    onChange={(e) => setSpecialOfferPrice(e.target.value)}
                  />
                </div>
                <div className='form-control-item2 w-100'>
                  <input
                    type='text'
                    id='description-input'
                    placeholder='popis(volitelné)'
                    className=''
                    value={specialOfferDescription}
                    onChange={(e) => setSpecialOfferDescription(e.target.value)}
                  />
                </div>
                <div className='error-text w-100 text-center'>
                  <p>{errorMessage}</p>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => uploadSpecialOffer()}
              >
                Nahrát
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='sidebar'>
          <span className='logo'>S</span>
          <a className='logo-expand' href='#'>
            <Logo />
          </a>
          <div className='side-wrapper'>
            <div className='side-title'>MENU</div>
            <div className='side-menu'>
              <a className='sidebar-link discover is-active' href='#'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                  <path d='M48 48a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm448 16H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z' />
                </svg>
                Týdenní nabídka
              </a>
              <a className='sidebar-link trending' href='#'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
                    <path d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z' />
                  </svg>{' '}
                </svg>
                Speciální nabídka
              </a>
            </div>
          </div>
          <div className='side-wrapper'>
            <div className='side-title'>Další nastavení</div>
            <div className='side-menu'>
              <a
                className='sidebar-link logout-btn'
                onClick={() => logOutUser()}
              >
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                  <path d='M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z' />
                </svg>
                Odhlásit se
              </a>
            </div>
          </div>
        </div>

        <div className='wrapper'>
          <div className='header mx-auto text-center'>
            <h1></h1>
          </div>
          <div className='main-container'>
            <div className='main-header anim'> Týdenní nabídka</div>

            {getMainBlog()}
            <div className='special-offer-header anim'>Speciální nabídka</div>
            <div className='main-blogs2 h-100'>
              <div className='main-blog2 anim'>{getSpecialOfferBlog()}</div>
              <div className='main-blog2 anim'>
                <div className='w-100 mx-auto'>
                  <button
                    data-toggle='modal'
                    data-target='#specialModal'
                    className='main-blog__title2 w-100 btn btn-outline-light'
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
