import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { APISpecialOfferProvider } from '../../../providers/API-providers/api-special-offer-provider';

const apiSpecialOfferProvider = new APISpecialOfferProvider();

export default function SpecialOfferAdminTile(props: any) {
  const id = props.id;

  const image = () => {
    if (props.imageSrc != undefined) {
      return <img width={300} src={props.imageSrc} alt='' />;
    } else {
      return <></>;
    }
  };

  const deleteTile = () => {
    apiSpecialOfferProvider
      .deleteSpecialOffer(id)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <div className='special-offer-tile special-offer-admin-tile'>
        <div className='w-100'>
          <div
            className='float-delete-icon ml-auto text-right'
            onClick={() => deleteTile()}
          >
            <DeleteForeverIcon />
          </div>
          <div className='food-menu-item'>
            <div className='food-menu-main'>
              <div className='food-menu-header'>
                <div className='pt-food-menu-title'>
                  <span className='title-wrap'>
                    <p>{props.itemName}</p>
                  </span>
                </div>
                {props.itemPrice == undefined ? (
                  <></>
                ) : (
                  <div className='pt-food-menu-price'>{props.itemPrice}</div>
                )}
              </div>
              <div className='food-menu-details'>
                <p>{props.description}</p>
              </div>
              <div
                className={
                  props.imageSrc != undefined
                    ? 'food-image-container text-center w-100 '
                    : 'food-image-container text-center w-100 d-none'
                }
              >
                {image()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
