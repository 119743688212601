import './drinks.css';
import Header from '../../global-components/Header';
import Footer from '../../global-components/Footer';
import PageHeader from '../../global-components/PageHeader';
import photoBackground from '../../assets/images/landing-photos/XT036159.jpg';
import MenuItem from '../../global-components/menu-item';
import beerPhotoBackground from '../../assets/images/landing-photos/XT036139.jpg';
import nonAlcoBackground from '../../assets/images/backgroundImages/cocacola.jpg';
import juiceBackground from '../../assets/images/backgroundImages/juice.jpg';
import vineBackground from '../../assets/images/backgroundImages/vine.jpg';
import teaBackground from '../../assets/images/backgroundImages/tea.jpg';
import coffeeBackground from '../../assets/images/backgroundImages/coffee.jpg';
import liquerBackground from '../../assets/images/backgroundImages/liquer.jpg';
import rumBackground from '../../assets/images/backgroundImages/rum.jpg';
import savourBackground from '../../assets/images/backgroundImages/jager.jpg';
import brandyBackground from '../../assets/images/backgroundImages/brandyjpg.jpg';
import coctailBackground from '../../assets/images/backgroundImages/coctails2.jpg';
import jumboBackground from '../../assets/images/backgroundImages/coctail.jpg';
import {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
} from 'react';
import apperitives from './drinksJSON';

import $ from 'jquery';

export default function Drinks() {
  const createArray = (beverageJsonArray: any[]) => {
    return beverageJsonArray.map(
      (val: {
        name: string | number | boolean | null | undefined;
        price: string | number | boolean | null | undefined;
        description: string | number | boolean | null | undefined;
        imageSRC: string | undefined;
      }) => {
        return (
          <MenuItem
            itemName={val.name}
            itemPrice={val.price}
            description={val.description}
            imageSrc={val.imageSRC}
          />
        );
      }
    );
  };

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      $('#float-button').removeClass('d-none');
    } else {
      $('#float-button').addClass('d-none');
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      scrollFunction();
    };
  }, []);

  return (
    <div id='drinks-page'>
      <a href='#' id='float-button' className='float d-none'>
        <i className='fas fa-arrow-up my-float'></i>
      </a>
      <Header position='top-fixed' />
      <div id='drinks'>
        <PageHeader
          customID='drinksBackground'
          backgroundImage={photoBackground}
          title='Drinky'
        />
      </div>



      <div className='w-100 container-fluid button-row '>
        <div className="reporting-nav mt-5">
          <div className="reporting-button-menu mx-auto">
            <a href='#apperitive' className="reporting-button">Aperitivy</a>
            <a href='#nealko' className="reporting-button">Nealko</a>
            <a href='#juice' className="reporting-button">Juice</a>
            <a href='#vine' className="reporting-button">Vína</a>
            <a href='#beers' className="reporting-button">Pivo</a>
            <a href='#specialCoffee' className="reporting-button">Special Káva</a>
          </div>
        </div>
        <div className="reporting-nav mt-5">
          <div className="reporting-button-menu mx-auto">
            <a href='#hot' className="reporting-button">Teplé nápoje</a>
            <a href='#liquers' className="reporting-button">Likéry</a>
            <a href='#rums' className="reporting-button">Rumy</a>
            <a href='#savour' className="reporting-button">Hořké likéry</a>
            <a href='#brandy' className="reporting-button">Koňaky</a>
            <a href='#koktails' className="reporting-button">Kokteily</a>
          </div>
        </div>
      </div>

      <div id='apperitive' className='pb-5 container-fluid apperitive'>
        <div className='col-12 mx-auto  list-parrent col-md-6 col-sm-8 col-lg-4'>
          <h1 className='list-header mx-auto'>Aperitivy</h1>
          {createArray(apperitives.apperitives)}
        </div>
      </div>
      <div id='nealko'>
        <PageHeader
          customID='nealkoID'
          backgroundImage={nonAlcoBackground}
          title='Nealkoholické nápoje'
        />
      </div>
      <div id='nealkoPhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto  list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Nealkoholické nápoje</h1>
            {createArray(apperitives.nonAlkoDrinks)}
          </div>
        </div>
      </div>
      <div id='juice'>
        <PageHeader
          customID='juiceID'
          backgroundImage={juiceBackground}
          title='Juice'
        />
      </div>
      <div id='juicePhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto  list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Juice</h1>
            {createArray(apperitives.juice)}
          </div>
        </div>
      </div>
      <div id='vine'>
        <PageHeader
          customID='vineID'
          backgroundImage={vineBackground}
          title='Rozlévaná vína'
        />
      </div>
      <div id='vinePhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto  list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Rozlévaná vína</h1>
            {createArray(apperitives.vines)}
          </div>
        </div>
      </div>

      <div id='beers'>
        <PageHeader
          customID='beerID'
          backgroundImage={beerPhotoBackground}
          title='Pivo'
        />
      </div>
      <div id='beersPhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto  list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Pivo</h1>
            {createArray(apperitives.beers)}
          </div>
        </div>
      </div>

      <div id='specialCoffee'>
        <PageHeader
          customID='specialCoffeeID'
          backgroundImage={coffeeBackground}
          title='Speciální káva'
        />
      </div>
      <div id='specialCoffeePhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto  list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Speciální káva</h1>
            {createArray(apperitives.specialniKava)}
          </div>
        </div>
      </div>
      <div id='hot'>
        <PageHeader
          customID='hotID'
          backgroundImage={teaBackground}
          title='Teplé nápoje'
        />
      </div>
      <div id='hotPhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto  list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Teplé nápoje</h1>
            {createArray(apperitives.hotBeverages)}
          </div>
        </div>
      </div>
      <div id='liquers'>
        <PageHeader
          customID='liquersID'
          backgroundImage={liquerBackground}
          title='Likéry'
        />
      </div>
      <div id='liquersPhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto  list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Likéry</h1>
            {createArray(apperitives.likers)}
          </div>
        </div>
      </div>
      <div id='rums'>
        <PageHeader
          customID='rumsID'
          backgroundImage={rumBackground}
          title='Rumy'
        />
      </div>
      <div id='rumsPhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Rumy</h1>
            {createArray(apperitives.rums)}
          </div>
        </div>
      </div>
      <div id='savour'>
        <PageHeader
          customID='savourID'
          backgroundImage={savourBackground}
          title='Hořké a bylinné likéry'
        />
      </div>
      <div id='savourPhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Hořké a bylinné likéry</h1>
            {createArray(apperitives.savourliquers)}
          </div>
        </div>
      </div>
      <div id='brandy'>
        <PageHeader
          customID='brandyID'
          backgroundImage={brandyBackground}
          title='Koňaky a brandy'
        />
      </div>
      <div id='brandyPhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Koňaky a brandy</h1>
            {createArray(apperitives.koniaks)}
          </div>
        </div>
      </div>
      <div id='koktails'>
        <PageHeader
          customID='koktailsID'
          backgroundImage={coctailBackground}
          title='Koktejly'
        />
      </div>
      <div id='koktailsPhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Koktejly</h1>
            {createArray(apperitives.coctails)}
          </div>
        </div>
      </div>
      <div id='special'>
        <PageHeader
          customID='specialID'
          backgroundImage={jumboBackground}
          title='Special Coctails'
        />
      </div>
      <div id='specialPhotos' className='pb-5 container-fluid apperitive'>
        <div className='row'>
          <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-4'>
            <h1 className='list-header mx-auto'>Special Coctails</h1>
            {createArray(apperitives.specialCoctails)}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
