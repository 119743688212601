export default function DameJidloIcon(){
    return(
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="18" height="18" viewBox="0 0 425.000000 414.000000"
         preserveAspectRatio="xMidYMid meet">
        
        <g transform="translate(0.000000,414.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path fill="#DC1C22" d="M637 4089 c-194 -25 -376 -160 -468 -344 -73 -149 -70 -53 -67 -1718
        l3 -1492 22 -58 c41 -106 87 -181 159 -253 57 -58 89 -81 165 -117 52 -25 118
        -50 147 -56 74 -15 2990 -15 3064 0 29 6 95 31 147 56 156 75 266 203 327 383
        18 53 19 116 19 1575 l0 1520 -24 70 c-37 108 -78 173 -160 256 -83 82 -150
        125 -257 161 l-69 23 -1470 1 c-811 1 -1501 -2 -1538 -7z m1665 -673 c475 -59
        811 -311 948 -711 56 -162 65 -229 64 -485 0 -215 -2 -245 -27 -360 -33 -157
        -66 -259 -123 -379 -197 -418 -575 -683 -1074 -752 -91 -12 -934 -25 -1014
        -15 -71 9 -122 49 -132 103 -8 43 4 218 61 868 8 94 24 278 35 410 83 1007
        100 1193 110 1219 13 36 60 86 94 101 46 20 896 21 1058 1z"/>
        <path fill="#FFFFFF" d="M2262 2680 c-27 -25 -28 -30 -42 -210 -43 -547 -46 -567 -94 -622
        -73 -83 -212 -74 -283 19 -35 45 -57 57 -84 42 -54 -28 -339 -272 -345 -294
        -16 -63 126 -211 259 -269 98 -43 193 -60 327 -60 145 1 237 21 360 82 100 48
        230 176 282 277 70 136 88 233 123 670 9 105 18 224 22 266 6 74 5 76 -22 97
        -27 21 -37 22 -254 22 -214 0 -228 -1 -249 -20z"/>
        </g>
        </svg>
        );   
}