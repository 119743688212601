import './Contact.css';
import Header from '../../global-components/Header';
import PageHeader from '../../global-components/PageHeader';
import pageHeaderBackground from '../../assets/images/IMG_6900.jpg';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import InstagramIcon from '@mui/icons-material/Instagram';
import DameJidloIcon from '../../assets/logos/dame-jidlo-icon';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
export default function Contact() {
  const position: any = [50.6652519, 13.8351368];

  return (
    <div id='contact-parrent'>
      <Header position='top-fixed' />
      <div id='contact'>
        <PageHeader
          customID='contactHeader'
          backgroundImage={pageHeaderBackground}
          title='Kontaktujte nás'
        />
      </div>
      <div id='contact-tiles'>
        <div className='row'>
          <div id='contact-map' className='col-12 col-md-4'>
            <MapContainer center={position} zoom={15} scrollWheelZoom={true}>
              <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
              <Marker position={position}>
                <Popup>Proboštovská 267, 417 12 Proboštov</Popup>
              </Marker>
            </MapContainer>
          </div>
          <div className='col-12 col-md-4 info'>
            <div className='mt-5 mx-auto text-center'>
              <h1>
                <b>Kontaktujte nás</b>
              </h1>
            </div>
            <div className='mt-4 mx-auto text-center'>
              <b>
                <LocationOnIcon className='mr-3' />
                <h3>Proboštovská 267, 417 12 Proboštov</h3>
              </b>
            </div>
            <div className='mt-4 mx-auto text-center'>
              <b>
                <LocalPhoneIcon className='mr-3' />
                <h3>
                  <a href='tel:776412349'>+420 776 412 349</a>
                </h3>
              </b>
            </div>
            <div className='mt-4 mx-auto text-center'>
              <b>
                <h3>
                  <a href='https://www.instagram.com/sokolovnarestaurant/' className='mr-4 instagram-ic'>
                    <InstagramIcon />
                  </a>

                  <a
                    href='https://www.facebook.com/SokolovnaProbostov'
                    className='ml-4 facebook-ic'
                  >
                    <FacebookIcon />
                  </a>
                </h3>
              </b>
            </div>
          </div>
          <div className='col-12 col-md-4  open-time'>
            <div className='mt-5 mx-auto text-center'>
              <h1>
                <b>Otevírací doba</b>
              </h1>
            </div>
            <div className='mt-3 mx-auto text-center'>
              <b>
                <h3>
                  <div className='row'>
                    <div className='col'> Pondělí</div>
                    <div className='col time'>11–23</div>
                  </div>
                </h3>
              </b>
            </div>
            <div className='mt-3 mx-auto text-center'>
              <b>
                <h3>
                  <div className='row'>
                    <div className='col'>Úterý</div>
                    <div className='col time'>11–23</div>
                  </div>
                </h3>
              </b>
            </div>
            <div className='mt-3 mx-auto text-center'>
              <b>
                <h3>
                  <div className='row'>
                    <div className='col'>Středa</div>
                    <div className='col time'>11–23</div>
                  </div>
                </h3>
              </b>
            </div>
            <div className='mt-3 mx-auto text-center'>
              <b>
                <h3>
                  <div className='row'>
                    <div className='col'>Čtvrtek</div>
                    <div className='col time'>11–23</div>
                  </div>
                </h3>
              </b>
            </div>
            <div className='mt-3 mx-auto text-center'>
              <b>
                <h3>
                  <div className='row'>
                    <div className='col'>Pátek</div>
                    <div className='col time'>11–23</div>
                  </div>
                </h3>
              </b>
            </div>
            <div className='mt-3 mx-auto text-center'>
              <b>
                <h3>
                  <div className='row'>
                    <div className='col'>Sobota</div>
                    <div className='col time'>11–24</div>
                  </div>
                </h3>
              </b>
            </div>
            <div className='mt-3 mx-auto text-center'>
              <b>
                <h3>
                  <div className='row'>
                    <div className='col'>Neděle</div>
                    <div className='col time'>11–24</div>
                  </div>
                </h3>
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
