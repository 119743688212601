const foods = {
  prepFood: [
    {
      name: 'Vídeňský hovězí guláš, HK (1, 3, 6)',
      price: '195 Kč',
      description: '150g',
      imageSRC: '',
    },
    {
      name: 'svíčková na smetaně, HK (1, 3, 7, 9, 10)',
      price: '195 Kč',
      description: '150g',
      imageSRC: '',
    },
    {
      name: 'Moravský vrabec, kysané zelí, HK (1, 3, 6)',
      price: '195 Kč',
      description: '150g',
      imageSRC: '',
    },
  ],
  predkrmy: [
    {
      name: 'Hovězí carpaccio s rukolou a parmazánem, bageta',
      price: '310 Kč',
      description: '(1,3,7,8) 100g',
      imageSRC: '',
    },
    {
      name:
        'Smažené Jalapeňos papričky plněné chedarem s pažitkovým dipem, bageta',
      price: '210 Kč',
      description: '(1,3,7) 4ks',
      imageSRC: '',
    },
    {
      name: 'Kozí sýr v Panko krustě, citrusové cooulis, bageta',
      price: '210 Kč',
      description: '(1, 3, 5, 7, 8) 100g',
      imageSRC: '',
    },
    {
      name:
        'Carpaccio z červené řepy rukola, balkánský sýr, hoblinky parmazánu, bageta',
      price: '210 Kč',
      description: '120g',
      imageSRC: '',
    },
    {
      name:
        'Tataráček z čerstvého lososa, petrželka, cibulka, citrón, topinky z toustového chleba',
      price: '210 Kč',
      description: '(1, 3, 4, 7, 10) 100g',
      imageSRC: '',
    },
    {
      name:
        'Krevety na česneku, majonéza se sušenými rajčaty, francouzská bageta',
      price: '250 Kč',
      description: '(1, 2, 3, 7) 100g',
      imageSRC: '',
    },
    {
      name: 'Tresčí játra, cibulka, citrón, čerstvý chléb',
      price: '180 Kč',
      description: '(1, 3, 7) 100g',
      imageSRC: '',
    },
  ],
  polevky: [
    {
      name: 'Kuřecí / hovězí vývar s masem a nudlemi',
      price: '60 Kč',
      description: '(dle denní nabídky) (1, 3)',
      imageSRC: '',
    },
    {
      name: 'Gulášová polévka, chléb',
      price: '85 Kč',
      description: '(1, 3, 7)',
      imageSRC: '',
    },
  ],
  steak: [
    {
      name: 'Beef steak s máslovým přelivem',
      price: '540 Kč / 900 Kč',
      description: '(7) 250g / 500g ',
      imageSRC: '',
    },
    {
      name: 'Beef steak s máslovým přelivem - Chateaubriand',
      price: '1850 Kč',
      description: '(7) 1000g',
      imageSRC: '',
    },
    {
      name: 'Bylinkový Rump steak',
      price: '420 Kč',
      description: '300g',
      imageSRC: '',
    },
    {
      name: 'Rib eye steak',
      price: '430 Kč',
      description: '(7) 250g',
      imageSRC: '',
    },
    {
      name: 'Kuřecí steak v provensálských bylinkách',
      price: '240 Kč',
      description: '(7) 250g',
      imageSRC: '',
    },
    {
      name: 'Steak z vepřové krkovice v pepřové marinádě z Cayanského pepře',
      price: '320 Kč',
      description: '(pikantní) (7) 300g',
      imageSRC: '',
    },
    {
      name: 'Steak z vepřové panenky v barevném pepři, pepřová omáčka',
      price: '370 Kč',
      description: '250g',
      imageSRC: '',
    },
    {
      name: 'Steak z vepřové kotlety s omáčkou chipotle BBQ',
      price: '290 Kč',
      description: '(9) 300g',
      imageSRC: '',
    },
  ],
  speciality: [
    {
      name: 'Tatarák z hovězího masa',
      price: '195 Kč / 195 Kč',
      description: '80g / 160g',
      imageSRC: '',
    },
    {
      name: 'Smažený mix řízek',
      price: '250 Kč',
      description: '(1, 3, 7) 200g',
      imageSRC: '',
    },
    {
      name: 'Marinovaná vepřová žebra, v pikantní omáčce s brusinkami, chléb',
      price: '295 Kč',
      description: '(1, 3, 7) 500g',
      imageSRC: '',
    },
    {
      name:
        'Hovězí burger s dvojitým sýrem, mleté steakové maso, chedar, slanina, cibule, majonéza, BBQ dip, okurka, rajče',
      price: '295 Kč',
      description:
        '(Burger podáváme s hranolkami, BQ a tatarskou omáčkou) (1, 3, 5, 6, 7, 10) 200g',
      imageSRC: '',
    },
    {
      name: 'Lososový burger se zeleninou, limetkovo-bazalková majonéza',
      price: '295 Kč',
      description:
        '(Burger podáváme s hranolkami, BBQ a tatarskou omáčkou) (1, 3, 4, 10) 200g',
      imageSRC: '',
    },
    {
      name: 'Burger se smaženým sýrem, zeleninou',
      price: '280 Kč',
      description:
        '(Burger podáváme s hranolkami, BBQ a tatrskou omáčkou) 200g',
      imageSRC: '',
    },
    {
      name: 'Quesadilla s kuřecím masem',
      price: '310 Kč',
      description:
        '(kuřecí maso, červená cibulka, kukuřice, cheddar, česnekový a pikantní dip) (1, 3, 7) 200g',
      imageSRC: '',
    },
    {
      name: 'Surf & Turf',
      price: '520 Kč',
      description:
        '(filírovaná hovězí svíčková, krevety na česneku, grenaille, majonéza se sušenými rajčaty) (1, 2, 3, 7) 200g',
      imageSRC: '',
    },
    {
      name:
        'Variace smažených sýrů eidam, hermelín, uzený sýr, tatarská a brusinková omáčka',
      price: '250 Kč',
      description: '(1, 3, 7) 220g',
      imageSRC: '',
    },
    {
      name: 'Klobása na grillu',
      price: '180 Kč',
      description: '200g',
      imageSRC: '',
    },
    {
      name: 'Kuřecí filírky s omáčkou z modrého sýra',
      price: '270 Kč',
      description: '(7) 200g',
      imageSRC: '',
    },
    {
      name:
        'Grilovaná vepřová panenka balená ve slanině, plněná Prosciutto Crudo s chedarem',
      price: '360 Kč',
      description: '(7, 10, 12) 200g',
      imageSRC: '',
    },
    {
      name:
        'Zauzané kachní prso podávané s cibulovou marmeládou s čerstvým chlebem',
      price: '380 Kč',
      description: '(1, 3, 7) 300g',
      imageSRC: '',
    },
    {
      name: 'Kuřecí prso na barevném pepři, limetkové coulis',
      price: '260 Kč',
      description: '(7) 250g',
      imageSRC: '',
    },
    {
      name:
        'Grilovaná kuřecí křídla se sweet chilli omáčkou, česnekovým dipem a zelným salátem s křenem ',
      price: '195 Kč / 310 Kč',
      description: '(chléb) (1, 3, 7)',
      imageSRC: '',
    },
  ],
  testoviny: [
    {
      name: 'Špagety Aglio Olio Peperoncino',
      price: '195 Kč',
      description: '(1, 3, 7) 100g',
      imageSRC: '',
    },
    {
      name: 'Špagety  s krevetami, česnekem a petrželkou',
      price: '280 Kč',
      description: '(1, 3, 7) 100g',
      imageSRC: '',
    },
    {
      name: 'Špagety Carbonara',
      price: '250 Kč',
      description: '(1, 3, 7) 100g',
      imageSRC: '',
    },
    {
      name: 'Penne s kuřecími kousky v hříbkovém ragů se smetanou',
      price: '260 Kč',
      description: '(1, 3, 7) 100g',
      imageSRC: '',
    },
    {
      name: 'Penne Arrabbiata',
      price: '230 Kč',
      description: '(rajčata, česnek, chilli paprička) 100g',
      imageSRC: '',
    },
    {
      name: 'Bramborové noky s kuřecím masem v hříbkovém ragů se smetanou',
      price: '270 Kč',
      description: '(1, 3, 7) 100g',
      imageSRC: '',
    },
    {
      name:
        'Bramborové noky s kuřecím masem, špenátem, česnekem a smetanovou omáčkou',
      price: '270 Kč',
      description: '(1, 3, 7) 100g',
      imageSRC: '',
    },
  ],
  kPivu: [
    {
      name: 'Nachos',
      price: '210 Kč',
      description:
        ' (zapečené s chedarem - sýrová omáčka, červená pikantní omáčka s brusinkami)(1, 3, 7) 80g',
      imageSRC: '',
    },
    {
      name:
        'Smažené kuřecí kousky v Panko krustě a sezamu, sweet-chilli, okurka, chléb',
      price: '230 Kč',
      description: '(1, 3, 7, 11) 200g',
      imageSRC: '',
    },
    {
      name: 'Mix nakládaných sýrů v oleji a česneku, čerstvý chléb',
      price: '180 Kč',
      description: '120g',
      imageSRC: '',
    },
    {
      name: 'Variace oliv',
      price: '180 Kč',
      description: '150g',
      imageSRC: '',
    },
    {
      name: 'Pražené solené mandle',
      price: '130 Kč',
      description: '80g',
      imageSRC: '',
    },
    {
      name: 'Kešu oříšky',
      price: '110 Kč',
      description: ' (8) 80g',
      imageSRC: '',
    },
    {
      name: 'Nakládané utopenci, chléb',
      price: '170 Kč',
      description: ' (1, 3, 7) 200g',
      imageSRC: '',
    },
    {
      name: 'Nakládaný hermelín, chléb',
      price: '180 Kč',
      description: '(1, 3, 7) 1ks',
      imageSRC: '',
    },
    {
      name: 'Retro topinka s kuřecím masem a sýrem',
      price: '160 Kč',
      description: '80g',
      imageSRC: '',
    },
    {
      name: 'Smažené syrečky, česnekový dip, chléb',
      price: '190 Kč',
      description: '(1, 3, 7) 4ks',
      imageSRC: '',
    },
    {
      name: 'Grilovaný špekáček na grilu',
      price: '170 Kč',
      description: '(hořčice, okurka, křen, chléb) (1, 3, 7, 10) 2ks',
      imageSRC: '',
    },
  ],
  ryby: [
    {
      name: 'Filátka ze pstruha pečené na másle',
      price: '340 Kč',
      description: '(4, 7) 200g',
      imageSRC: '',
    },
    {
      name: 'Steak z čerstvého lososa s grilovanou zeleninou',
      price: '395 Kč',
      description: '(4, 7) 200g',
      imageSRC: '',
    },
    {
      name: 'Krevety na francouzský způsob, bageta',
      price: '430 Kč',
      description: '(česnek, petržel, smetana, máslo) (1, 2, 3, 7) 200g',
      imageSRC: '',
    },
  ],
  salaty: [
    {
      name: 'Ceasar salát s kuřecím masem, krutony a hoblinky parmazánu',
      price: '240 Kč',
      description: '(1, 3, 4, 7, 10) 100g',
      imageSRC: '',
    },
    {
      name: 'Caesar salát s krevetami, krutony a hoblinkami parmazánu',
      price: '290 Kč',
      description: '(1, 2, 3, 7) 80g',
      imageSRC: '',
    },
    {
      name:
        'Originální řecký salát se sýrem Feta a olivami, francouzská bageta',
      price: '240 Kč',
      description: '(1, 3, 7) 100g',
      imageSRC: '',
    },
    {
      name:
        'Zeleninový salát s kuřecími steaky a pečenými baby brambůrky, olivový olej',
      price: '260 Kč',
      description: '100g',
      imageSRC: '',
    },
    {
      name: 'Salát s grilovaným lososem a balkánským sýrem',
      price: '280 Kč',
      description: '(mix zelených salátů s čerstvou zeleninou) (4, 7) 100g',
      imageSRC: '',
    },

    {
      name: 'Baby Mozzarella v mix salátu, bageta',
      price: '240 Kč',
      description:
        '(bazalka, cherry rajčátka, mix zelených salátů, balsamico) (1, 3, 7) 100g',
      imageSRC: '',
    },

    {
      name:
        'Listový salát s bazalkovo-limetkovou majonézou, variací sýrů, vlašské ořechy, hroznové víno, semínka a schuary',
      price: '310 Kč',
      description: '(1, 3, 5, 7, 8) 100g',
      imageSRC: '',
    },
  ],
  prilohy: [
    {
      name: 'Hranolky',
      price: '55 Kč',
      description: '150g',
      imageSRC: '',
    },
    {
      name: 'Krokety',
      price: '55 Kč',
      description: '(1, 3) 150g',
      imageSRC: '',
    },
    {
      name: 'Francouzské brambůrky Grenaille',
      price: '70 Kč',
      description: '(pečené s bylinkami) (7) 200g',
      imageSRC: '',
    },
    {
      name: 'Šťouchané brambory s cibulkou, slaninou a petrželkou',
      price: '70 Kč',
      description: '(7) 200g',
      imageSRC: '',
    },
    {
      name: 'Grilovaná zelenina',
      price: '90 Kč',
      description: '(lilek, cuketa, paprika, žampión, cibule) 200g',
      imageSRC: '',
    },
    {
      name: 'Rýže vařená',
      price: '95 Kč',
      description: '100g',
      imageSRC: '',
    },
    {
      name: 'Hráškové lusky na másle s česnekem',
      price: '95 Kč',
      description: '(7) 150g',
      imageSRC: '',
    },
    {
      name: 'Kukuřice na grilu',
      price: '85 Kč',
      description: '(7)1ks',
      imageSRC: '',
    },
    {
      name: 'Francouzská bageta',
      price: '40 Kč',
      description: '(1, 3, 7) 1ks',
      imageSRC: '',
    },
    {
      name: 'Chléb',
      price: '10 Kč',
      description: '(1, 3, 7) 1ks',
      imageSRC: '',
    },
  ],
  omacky: [
    {
      name: 'Pepřová omáčka',
      price: '40 Kč',
      description: '(7)',
      imageSRC: '',
    },
    {
      name: 'BBQ',
      price: '40 Kč',
      description: '',
      imageSRC: '',
    },
    {
      name: 'Omáčka z modrého sýra',
      price: '55 Kč',
      description: '(7)',
      imageSRC: '',
    },
    {
      name: 'Bazalkovo-limetková majonéza',
      price: '40 Kč',
      description: '(3)',
      imageSRC: '',
    },
    {
      name: 'Hříbkové ragů',
      price: '55 Kč',
      description: '(7)',
      imageSRC: '',
    },
    {
      name: 'Česnekový dip',
      price: '40 Kč',
      description: '(7)',
      imageSRC: '',
    },
    {
      name: 'Pažitková lučina',
      price: '40 Kč',
      description: '(7)',
      imageSRC: '',
    },
    {
      name: 'Tatarská omáčka',
      price: '40 Kč',
      description: 'domácí (3, 10)',
      imageSRC: '',
    },
    {
      name: 'Sweet-Chilli',
      price: '40 Kč',
      description: '',
      imageSRC: '',
    },
    {
      name: 'Majonéza se sušenými rajčaty',
      price: '40 Kč',
      description: '(3)',
      imageSRC: '',
    },
    {
      name: 'Pálivá s brusinkami',
      price: '40 Kč',
      description: '',
      imageSRC: '',
    },
    {
      name: 'Plnotučná hořčice',
      price: '30 Kč',
      description: '(10)',
      imageSRC: '',
    },
    {
      name: 'Krémžská hořčice',
      price: '30 Kč',
      description: '(10)',
      imageSRC: '',
    },
    {
      name: 'Kečup',
      price: '30 Kč',
      description: '',
      imageSRC: '',
    },
    {
      name: 'Křen',
      price: '40 Kč',
      description: '',
      imageSRC: '',
    },
  ],
  dezerty: [
    {
      name: 'Čoko-fondant s vanilkovou zmrzlinou a šlehačkou',
      price: '125 Kč',
      description: '(1, 3, 7)',
      imageSRC: '',
    },
    {
      name:
        'Domácí jablečný závin s rumem, vlašskými ořechy, vanilkovou zmrzlinou a šlehačkou',
      price: '115 Kč',
      description: '(1, 3, 7, 8)',
      imageSRC: '',
    },
    {
      name: 'Palačinka s jahodovou marmeládou nebo nutellou',
      price: '110 Kč',
      description: '(šlehačka zmrzlina) (1, 3, 7)',
      imageSRC: '',
    },
    {
      name: 'Hočké maliny s vanilkovou zmrzlinou a šlehačkou',
      price: '140 Kč',
      description: '(7)',
      imageSRC: '',
    },
    {
      name: 'Vanilková zmrzlina s pistáciemi a mléčnou čokoládou',
      price: '155 Kč',
      description: '(7, 8)',
      imageSRC: '',
    },
    {
      name: 'CHURROS',
      price: '155 Kč',
      description:
        '(tradičnní smažené skořicové tyčinky s mléčnou čokoládou)(7)',
      imageSRC: '',
    },
  ],
};

export default foods;
