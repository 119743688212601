import aperol from '../../assets/images/drinks/aperol.JPG';
import campari from '../../assets/images/drinks/campari.jpg';
import martiniBianco from '../../assets/images/drinks/martini-bianco.jpg';
import martiniRoso from '../../assets/images/drinks/martini roso.jpg';
import alzirskaKava from '../../assets/images/drinks/alzirska-kava.jpg';
//import b52 from '../../assets/images/drinks/B52.jpg';
import bailies from '../../assets/images/drinks/bailies.jpg';
import baileys from '../../assets/images/drinks/baileys.png';
import becherovka from '../../assets/images/drinks/becherovka.png';
import berentzen from '../../assets/images/drinks/berentzen.jpg';
import bernardSvestka from '../../assets/images/drinks/bernard-svestka.png';
import bileVino from '../../assets/images/drinks/bile-vino.jpg';
import birelpomelogrep from '../../assets/images/drinks/birelpomelogrep.png';
import bombardino from '../../assets/images/drinks/bombardino.jpg';
import bozkovRum from '../../assets/images/drinks/bozkov-rum.jpg';
import brugalRum from '../../assets/images/drinks/brugal-rum.jpg';
import brumbuRum from '../../assets/images/drinks/brumbu-rum.jpg';
import caj from '../../assets/images/drinks/caj.jpg';
import cappuchino from '../../assets/images/drinks/cappuchino.jpg';
import captainMorgan from '../../assets/images/drinks/captain-morgan.jpg';
import cerveneVino from '../../assets/images/drinks/cervene-vino.jpg';
import cocacola from '../../assets/images/drinks/cocacola.jpg';
import cointreau from '../../assets/images/drinks/cointreau.png';
import corona from '../../assets/images/drinks/corona.jpg';
import desperatos from '../../assets/images/drinks/desperatos.jpg';
import diplomatico from '../../assets/images/drinks/diplomatico.jpg';
import donpapa7 from '../../assets/images/drinks/donpapa7.jpg';
import donpapa10 from '../../assets/images/drinks/donpapa10.jpeg';
import donpapa15 from '../../assets/images/drinks/donpapa15.jpg';
import esperoCaribian from '../../assets/images/drinks/espero-caribian.jpg';
import espresso from '../../assets/images/drinks/espresso.jpg';
import fernetStock from '../../assets/images/drinks/fernet-stock.jpg';
import frappe from '../../assets/images/drinks/frappe.jpg';
import frisco from '../../assets/images/drinks/Frisco.jpeg';
import gambrinus11 from '../../assets/images/drinks/gambrinus11.png';
import grandMarnier from '../../assets/images/drinks/grand-marnier.jpg';
import griotka from '../../assets/images/drinks/griotka.jpeg';
import griotka2 from '../../assets/images/drinks/griotka.jpg';
import grog from '../../assets/images/drinks/grog.jpg';
import havanaClub from '../../assets/images/drinks/havana-club.jpg';
import havanaEspecial from '../../assets/images/drinks/havana-especial.png';
import hennessyVS from '../../assets/images/drinks/hennessy-vs.jpg';
import hennessyVSOP from '../../assets/images/drinks/hennessy-vsop.jpg';
import horkaCokolada from '../../assets/images/drinks/horka-cokolada.jpg';
import horkeJablko from '../../assets/images/drinks/horke-jablko.jpg';
import irskaKava from '../../assets/images/drinks/irska-kava.jpg';
import jagermeister from '../../assets/images/drinks/jagermeister.jpg';
import jagermesterScharf from '../../assets/images/drinks/jagermeister-scharf.jpg';
import jagermeisterManifest from '../../assets/images/drinks/jagermeister-manifest.jpg';
import jaritos from '../../assets/images/drinks/jaritos.jpg';
import juice from '../../assets/images/drinks/juice.jpg';
import juice2 from '../../assets/images/drinks/juice2.jpg';
import kahlua from '../../assets/images/drinks/kahlua.jpg';
import kavaDecaf from '../../assets/images/drinks/kava-bez-kofeinu.jpg';
import kingswood from '../../assets/images/drinks/kingswood.png';
import kofola from '../../assets/images/drinks/kofola.jpg';
import latteMachiato from '../../assets/images/drinks/latte-machiato.jpg';
import ledovaKava from '../../assets/images/drinks/ledova-kava.jpg';
import legendario from '../../assets/images/drinks/legendario.jpg';
import malibu from '../../assets/images/drinks/malibu.png';
import malinovka from '../../assets/images/drinks/malinovka.png';
import martelVS from '../../assets/images/drinks/martel-vs.png';
import martelXO from '../../assets/images/drinks/martel-xo.png';
import mataCaj from '../../assets/images/drinks/mata-caj.jpg';
import metaxa7 from '../../assets/images/drinks/metaxa-7.jpg';
import metaxa12 from '../../assets/images/drinks/metaxa-12.jpg';
import metaxa from '../../assets/images/drinks/metaxa.jpg';
import pampero from '../../assets/images/drinks/pampero.jpg';
import perrier from '../../assets/images/drinks/perrier.jpg';
import pinkTonic from '../../assets/images/drinks/pink-tonic.jpg';
import plzen from '../../assets/images/drinks/plzen.png';
import prosecco from '../../assets/images/drinks/prosecco.jpg';
import rajecVoda from '../../assets/images/drinks/rajec-voda.jpg';
import redbull from '../../assets/images/drinks/redbull.jpg';
import remyMartinVSOP from '../../assets/images/drinks/remy-martin-vsop.jpg';
import remyMartin from '../../assets/images/drinks/remy-martin.jpg';
import republicaRum from '../../assets/images/drinks/republica-rum.jpg';
import royalcrownCola from '../../assets/images/drinks/royalcrownCola.png';
import ruzoveVino from '../../assets/images/drinks/ruzove-vino.jpg';
import staraMyslivecka from '../../assets/images/drinks/stara-myslivecka.jpg';
import tonic from '../../assets/images/drinks/tonic.jpg';
import tureckaKava from '../../assets/images/drinks/turecka-kava.jpg';
import vajecnak from '../../assets/images/drinks/vajecnak.jpg';
import voda from '../../assets/images/drinks/voda.jpg';
import zacapaXO from '../../assets/images/drinks/zacapa-xo.jpg';
import zacapa23 from '../../assets/images/drinks/zacapa23.jpg';
import cordino from '../../assets/images/drinks/crodino.png';
import zelena from '../../assets/images/drinks/zelena.jpg';

const apperitives = {
  nonAlkoDrinks: [
    {
      name: 'Coca-cola,Fanta,Sprite',
      price: '50 Kč',
      description: '0,2 l',
      imageSRC: '',
    },
    {
      name: 'Royal Crown Cola',
      price: '50 Kč',
      description: '0,25 l',
      imageSRC: '',
    },
    {
      name: 'Tonic',
      price: '50 Kč',
      description: 'Tonic Zero, Ginger Ale, Bitter Rose 0,25 l',
      imageSRC: '',
    },
    {
      name: 'Red Bull',
      price: '95 Kč',
      description: '0,25 l',
      imageSRC: '',
    },
    {
      name: 'Fever Tree tonic',
      price: '80 Kč',
      description: '0,2 l',
      imageSRC: '',
    },
    {
      name: 'Ledový čaj',
      price: '50 Kč',
      description: 'zelený, citronový, broskvový 0,25 l',
      imageSRC: '',
    },
    {
      name: 'Kofola',
      price: '35 Kč / 45 Kč',
      description: 'točená 0,3 l / 0,5 l',
      imageSRC: '',
    },
    {
      name: 'Malinovka',
      price: '35 Kč / 45 Kč',
      description: 'točená 0,3 l / 0,5 l',
      imageSRC: '',
    },
    
    {
      name: 'Voda Rajec',
      price: '45 Kč',
      description: 'perlivá, neperlivá, jemně perlivá 0,33 l',
      imageSRC: '',
    },
    {
      name: 'Voda Rajec',
      price: '110 Kč',
      description: '0,75 l',
      imageSRC: '',
    },
    {
      name: 'Perrier',
      price: '85 Kč',
      description: '0,5 l',
      imageSRC: '',
    },
    {
      name: 'Kohoutová voda',
      price: '35 Kč / 45 Kč',
      description: 'led a citrón 0,5 l / 1l',
      imageSRC: '',
    },
  ],
  juice: [
    {
      name: 'Jahoda, Hruška, Černý rybíz',
      price: '55 Kč',
      description: '0,2 l',
      imageSRC: '',
    },
    {
      name: 'Pomeranč, jablko, multivitamin',
      price: '40 Kč / 50 Kč',
      description: '0,2 l / 0,3 l',
      imageSRC: '',
    },
  ],
  vines: [
    {
      name: 'Bílé víno',
      price: '40 Kč / 60 Kč / 115 Kč',
      description: '0,1 l / 0,2 l / 0,5 l',
      imageSRC: '',
    },
    {
      name: 'Růžové víno',
      price: '40 Kč / 60 Kč / 115 Kč',
      description: '0,1 l / 0,2 l / 0,5 l',
      imageSRC: '',
    },
    {
      name: 'Červené víno',
      price: '40 Kč / 60 Kč / 115 Kč',
      description: '0,1 l / 0,2 l / 0,5 l',
      imageSRC: '',
    },
    {
      name: 'Prosecco',
      price: '70 Kč',
      description: '0,1 l / 0,2 l',
      imageSRC: '',
    },
    {
      name: 'Vinný střik',
      price: '80 Kč',
      description: '0,5 l',
      imageSRC: '',
    },
    {
      name: 'Bambus',
      price: '90 Kč',
      description: '0,5 l',
      imageSRC: '',
    },
  ],
  specialniKava: [
    {
      name: 'Ledová káva',
      price: '120 Kč',
      description: 'vanilková zmrzlina, šlehačka',
      imageSRC: '',
    },
    {
      name: 'Frappé',
      price: '95 Kč',
      description: 's mlékem, bez mléka',
      imageSRC: '',
    },
  ],
  hotBeverages: [
    {
      name: 'Espresso',
      price: '55 Kč',
      description: '(7) 7g',
      imageSRC: '',
    },
    {
      name: 'Cappuccino',
      price: '65 Kč',
      description: '(7) 7g',
      imageSRC: '',
    },
    {
      name: 'Latte Macchiato',
      price: '70 Kč',
      description: '(7) 7g',
      imageSRC: '',
    },
    {
      name: 'Káva bez kofeinu',
      price: '55 Kč',
      description: '(7) 7g',
      imageSRC: '',
    },
    {
      name: 'Turecká káva',
      price: '50 Kč',
      description: '(7) 7g',
      imageSRC: '',
    },
    {
      name: 'Vídeňská káva se šlehačkou',
      price: '75 Kč',
      description: '(7) 7g',
      imageSRC: '',
    },
    {
      name: 'Irská káva',
      price: '90 Kč',
      description: '2cl irská whiskey, šlehačka (7)',
      imageSRC: '',
    },
    {
      name: 'Alžírská káva',
      price: '90 Kč',
      description: '2cl vaječný likér, šlehačka (7)',
      imageSRC: '',
    },
    {
      name: 'Baileys káva',
      price: '90 Kč',
      description: '2cl Baileys, šlehačka (7)',
      imageSRC: '',
    },
    {
      name: 'Bombardino',
      price: '90 Kč',
      description: '4cl Bombardino, šlehačka',
      imageSRC: '',
    },
    {
      name: 'Grog',
      price: '60 Kč',
      description: '4cl tuzemský rum',
      imageSRC: '',
    },
    {
      name: 'Horká griotka',
      price: '70 Kč',
      description: '4cl Griotte',
      imageSRC: '',
    },
    {
      name: 'Horké jablko',
      price: '60 Kč',
      description: '',
      imageSRC: '',
    },
    {
      name: 'Horká čokoláda',
      price: '70 Kč',
      description: '',
      imageSRC: '',
    },
    {
      name: 'Čaj',
      price: '60 Kč',
      description: 'černý, ovocný, zelený, heřmánek s mátou',
      imageSRC: '',
    },
    {
      name: 'Čaj z čersvé máty',
      price: '70 Kč',
      description: '',
      imageSRC: '',
    },
    {
      name: 'Čaj z čerstvého zázvoru',
      price: '70 Kč',
      description: '',
      imageSRC: '',
    },
  ],
  likers: [
    {
      name: 'Baileys',
      price: '70 Kč',
      description: '(3,7) -- 0,04 l',
      imageSRC: baileys,
    },
    {
      name: 'Káhlua',
      price: '70 Kč',
      description: '(6, 7) -- 0,04 l',
      imageSRC: kahlua,
    },
    {
      name: 'Malibu',
      price: '70 Kč',
      description: '(8) -- 0,04 l',
      imageSRC: malibu,
    },
    {
      name: 'Cointreau',
      price: '70 Kč',
      description: ' 0,04 l',
      imageSRC: cointreau,
    },
    {
      name: 'Grand Marnier',
      price: '70 Kč',
      description: ' 0,04 l',
      imageSRC: grandMarnier,
    },
    {
      name: 'Griotte',
      price: '60 Kč',
      description: ' 0,04 l',
      imageSRC: griotka2,
    },
    {
      name: 'Vaječný likér',
      price: '60 Kč',
      description: '(3, 7) 0,04 l',
      imageSRC: vajecnak,
    },
    {
      name: 'Zelená',
      price: '60 Kč',
      description: ' 0,04 l',
      imageSRC: zelena,
    },
  ],
  rums: [
    {
      name: 'Havana club',
      price: '70 Kč',
      description: 'Aňejo 3, Verde (Kuba) 0,04 l',
      imageSRC: havanaClub,
    },
    {
      name: 'Havana Especial',
      price: '80 Kč',
      description: '(Kuba) 0,04 l',
      imageSRC: havanaEspecial,
    },
    {
      name: 'Legendario',
      price: '90 Kč',
      description: 'Elixir, Blanco, Dorado (Kuba) 0,04 l',
      imageSRC: legendario,
    },
    {
      name: 'Espero Caribean',
      price: '105 Kč',
      description: 'Orange, Káva, Kokos 0,04 l',
      imageSRC: esperoCaribian,
    },
    {
      name: 'Brugal',
      price: '90 Kč',
      description: '(Dominikánská) 0,04 l',
      imageSRC: brugalRum,
    },
    {
      name: 'Pampero Aniversario',
      price: '120 Kč',
      description: '(Venezuela) 0,04 l',
      imageSRC: pampero,
    },
    {
      name: 'Diplomatico 12 yo',
      price: '130 Kč',
      description: '(Venezuela) 0,04 l',
      imageSRC: diplomatico,
    },
    {
      name: 'Diplomatico Mantuano',
      price: '110 Kč',
      description: '(Venezuela) 0,04 l',
      imageSRC: '',
    },
    {
      name: 'Don Papa 7 yo',
      price: '130 Kč',
      description: '(Filipíny) 0,04 l',
      imageSRC: donpapa7,
    },
    {
      name: 'Don Papa 10 yo',
      price: '240 Kč',
      description: '(Filipíny) 0,04 l',
      imageSRC: donpapa10,
    },
    {
      name: 'Prohibido 12 yo',
      price: '130 Kč',
      description: '(Mexiko) 0,04 l',
      imageSRC: '',
    },
    {
      name: 'Prohibido 15 yo',
      price: '180 Kč',
      description: '(Mexiko) 0,04 l',
      imageSRC: '',
    },
    {
      name: 'Zacapa 23',
      price: '170 Kč',
      description: '(Guetamala) 0,04 l',
      imageSRC: zacapa23,
    },
    {
      name: 'Captain Morgan spiced gold',
      price: '60 Kč',
      description: 'Spiced, Tiki (Jamajka) 0,04 l',
      imageSRC: captainMorgan,
    },
    {
      name: 'Božkov',
      price: '55 Kč',
      description: 'Originál, káva (Česko) 0,04 l',
      imageSRC: bozkovRum,
    },
    {
      name: 'Republika',
      price: '80 Kč',
      description: 'Exclusive, espresso (Česko) 0,04 l',
      imageSRC: republicaRum,
    },
  ],
  savourliquers: [
    {
      name: 'Jägermeister',
      price: '70 Kč',
      description: '0,04 l',
      imageSRC: jagermeister,
    },
    {
      name: 'Jägermeister Scharf',
      price: '70 Kč',
      description: '0,04 l',
      imageSRC: jagermesterScharf,
    },
    {
      name: 'Jägermeister Manifest',
      price: '90 Kč',
      description: '0,04 l',
      imageSRC: jagermeisterManifest,
    },
    {
      name: 'Becherovka original, lemond',
      price: '60 Kč',
      description: '0,04 l',
      imageSRC: becherovka,
    },
    {
      name: 'Fernet Stock, citrus',
      price: '60 Kč',
      description: '0,04 l',
      imageSRC: fernetStock,
    },
    {
      name: 'Stará myslivecká',
      price: '55 Kč',
      description: '0,04 l',
      imageSRC: staraMyslivecka,
    },
    {
      name: 'Berentzen',
      price: '60 Kč',
      description: 'švestka, jablko, hruška 0,04 l',
      imageSRC: berentzen,
    },
  ],
  koniaks: [
    {
      name: 'Hennessy V.S.',
      price: '130 Kč',
      description: '0,04 l',
      imageSRC: hennessyVS,
    },
    {
      name: 'Hennessy V.S.O.P.',
      price: '160 Kč',
      description: '0,04 l',
      imageSRC: hennessyVSOP,
    },
    {
      name: 'Rémy Martin V.S.O.P',
      price: '160 Kč',
      description: '0,04 l',
      imageSRC: remyMartinVSOP,
    },

    {
      name: 'Martell V.S.',
      price: '130 Kč',
      description: '0,04 l',
      imageSRC: martelVS,
    },
    {
      name: 'Metaxa 5*',
      price: '70 Kč',
      description: '0,04 l',
      imageSRC: metaxa,
    },
    {
      name: 'Metaxa 7*',
      price: '90 Kč',
      description: '0,04 l',
      imageSRC: metaxa7,
    },
    {
      name: 'Metaxa 12*',
      price: '130 Kč',
      description: '0,04 l',
      imageSRC: metaxa12,
    },
  ],
  coctails: [
    {
      name: 'Mojito',
      price: '125 Kč',
      description:
        '(Havana rum, limetka, máta, třtinový cukr, lime juice, soda)',
      imageSRC: '',
    },
    {
      name: 'Virgin Mojito',
      price: '100 Kč',
      description: '(limetka, máta, třtinový cukr, lime juice, soda)',
      imageSRC: '',
    },
    {
      name: 'Cuba Libre',
      price: '115 Kč',
      description: '(Havana rum, Coca-Cola, limetka)',
      imageSRC: '',
    },
    {
      name: 'Jahodové Daiquiri',
      price: '140 Kč',
      description: '(Havana rum, maracuja piré, třtinový cukr, triple sec)',
      imageSRC: '',
    },
    {
      name: 'Sex on the beach',
      price: '140 Kč',
      description:
        '(vodka, broskvový likér, grenadina, pomerančový  a brusinkový juice)',
      imageSRC: '',
    },
    {
      name: 'Long Island iced tea',
      price: '170 Kč',
      description: '(vodka, gin, tequila, havana, lime juice, Coca-Cola)',
      imageSRC: '',
    },
    {
      name: 'Moscow mule',
      price: '160 Kč',
      description: '(vodka, limetka, zázvorové pivo)',
      imageSRC: '',
    },
    {
      name: 'Cosmopolian',
      price: '140 Kč',
      description: '(citronová vodka, Cointreau, lime juice, brusinkový juice)',
      imageSRC: '',
    },
    {
      name: '140 Sunrise',
      price: '130 Kč',
      description: '(tequila, grenadina, pomerančový juice)',
      imageSRC: '',
    },
    {
      name: 'B-52',
      price: '115 Kč',
      description: '(tequila, grenadina, pomerančový juice)',
      imageSRC: '',
    },
    {
      name: 'Chupito',
      price: '115 Kč',
      description: '(Havana rum, lime juice, koktejlová třešeň)',
      imageSRC: '',
    },
    {
      name: 'Aperol Spritz',
      price: '115 Kč',
      description: '(Aperol, Prosecco, soda, pomeranč)',
      imageSRC: '',
    },
    {
      name: 'Maracuja Spritz',
      price: '115 Kč',
      description: '(Prosecco, marcuja, pomeranč)',
      imageSRC: '',
    },
    {
      name: 'Gin Tonic',
      price: '115 Kč',
      description: '(Beefeater, tonic, citrón)',
      imageSRC: '',
    }
  ],
  specialCoctails: [
    {
      name: 'Jumbo Mojito (6 osob)',
      price: '590 Kč',
      description:
        '(Havana rum, limetka, máta, třtinový cukr, lime juice, soda)',
      imageSRC: '',
    },
    {
      name: 'Jumbo Cuba libre (6 osob)',
      price: '590 Kč',
      description: '(Havana rum, Coca-Cola, limetka)',
      imageSRC: '',
    },
    {
      name: 'Jumbo Long Island iced tea (6 osob)',
      price: '690 Kč',
      description: '(vodka, gin, tequila, havana, lime juice, Coca-Cola)',
      imageSRC: '',
    },
  ],
  apperitives: [
    {
      name: 'Campari',
      price: '70 Kč',
      description: '0,05 l',
      imageSRC: '',
    },
    {
      name: 'Martini Bianco',
      price: '70 Kč',
      description: '0,1 l',
      imageSRC: '',
    },
    {
      name: 'Martini Rosso',
      price: '70 Kč',
      description: '0,1 l',
      imageSRC: '',
    },
    {
      name: 'Aperol',
      price: '70 Kč',
      description: '0,05 l',
      imageSRC: '',
    },
    {
      name: 'Aperol - bez alkoholu',
      price: '70 Kč',
      description: '0,05 l',
      imageSRC: '',
    },
    {
      name: 'Crodino - bez alkoholu',
      price: '90 Kč',
      description: '0,1 l',
      imageSRC: '',
    },
  ],
  beers: [
    {
      name: 'Pilsner Urquell ',
      price: '110 Kč',
      description: 'ležák - Na čepu, 1 l',
      imageSRC: '',
    },
    {
      name: 'Pilsner Urquell ',
      price: '60 Kč',
      description: 'ležák - Na čepu, 0,5 l',
      imageSRC: '',
    },
    {
      name: 'Pilsner Urquell ',
      price: '50 Kč',
      description: 'ležák "šnyt" - Na čepu, 0,5 l',
      imageSRC: '',
    },
    {
      name: 'Gambrinus 11° ',
      price: '40 Kč',
      description: 'Nepasterizovaná 11° - Na čepu, 0,3 l',
      imageSRC: '',
    },
    {
      name: 'Gambrinus 11° ',
      price: '50 Kč',
      description: 'Nepasterizovaná 11° - Na čepu, 0,5 l',
      imageSRC: '',
    },
    {
      name: 'Kingswood originál, kingswood rosé ',
      price: '75 Kč',
      description: '0,33 l',
      imageSRC: '',
    },
    {
      name: 'Birell světlý, polotmavý',
      price: '45 Kč',
      description: 'lahev nealko- Na čepu 0,33 l',
      imageSRC: '',
    },
    {
      name: 'Birell',
      price: '45 Kč',
      description: ' pomelo/grep, malina a limeta, červený pomeranč - Na čepu 0,5 l',
      imageSRC: '',
    },
    {
      name: 'Frisco',
      price: '80 Kč',
      description: ' 0,33 l',
      imageSRC: '',
    },
    {
      name: 'Bernard švestka',
      price: '50 Kč',
      description: 'lahev, nealko - 0,33 l',
      imageSRC: '',
    },
    {
      name: 'Desperados',
      price: '95 Kč',
      description: ' 0,33 l',
      imageSRC: '',
    },
    {
      name: 'Corona extra',
      price: '95 Kč',
      description: ' 0,33 l',
      imageSRC: '',
    },
  ],
};

export default apperitives;
