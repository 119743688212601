import './Coupons.css';

import { useCallback, useState } from 'react';

import ImageViewer from 'react-simple-image-viewer';
import Header from '../../global-components/Header';
import Footer from '../../global-components/Footer';

import voucherpivo from '../../assets/images/voucher/VoucherPivo.jpg';
import voucher2 from '../../assets/images/voucher/Voucher2.jpg';

export default function Coupons() {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const imageArray = [voucher2, voucherpivo];
  const openImage = useCallback((imageIndex: number) => {
    setIsViewerOpen(true);
    setImageIndex(imageIndex);
  }, []);

  const closeImage = useCallback(() => {
    setIsViewerOpen(false);
  }, []);
  return (
    <>
      {isViewerOpen && (
        <ImageViewer
          src={imageArray}
          currentIndex={imageIndex}
          onClose={closeImage}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
      <div id='coupons'>
        <Header position='top-fixed' />
        <div className='container-fluid coupons-container'>
          <div className='row voucher-tile'>
            <div className='col-12 col-sm-6 voucher-image'>
              <img onClick={() => openImage(0)} src={voucher2} alt='' />
            </div>
            <div className='col-12 col-sm-6 voucher-text'>
              <p>
                U nás si také můžete zakoupit dárkové vouchery, které jsou
                skvělým dárkem k narozeninám, či k jiným příležitostem. Jsou k
                dostání u nás na pokladně.
              </p>
            </div>
          </div>
          <div className='row voucher-tile pivo'>
            <div className='col-12 col-sm-6 voucher-text '>
              <p>
                Dále je možné si zakoupit vouchery pouze na pivo. Také skvělý
                dárek. k dostání u nás na pokladně.
              </p>
            </div>
            <div className='col-12 col-sm-6 voucher-image'>
              <img onClick={() => openImage(1)} src={voucherpivo} alt='' />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
