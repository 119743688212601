export default function Logo(props){
    return (<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     className="main-logo "  viewBox="0 0 862.000000 182.000000"
    preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,182.000000) scale(0.100000,-0.100000)"
   fill="#FFFFFF" stroke="none">
   <path  stroke="#000000" stroke-width="20" d="M430 1749 c-35 -54 -53 -108 -63 -189 -4 -26 -5 -24 -23 28 -5 13 -9
   10 -21 -18 -15 -34 -14 -97 2 -145 4 -13 -7 -6 -29 19 l-36 39 0 -37 c0 -83
   59 -154 186 -225 117 -64 167 -104 192 -153 13 -23 15 -32 5 -18 -37 54 -104
   107 -198 160 -129 72 -224 160 -267 248 -31 62 -33 74 -37 190 -2 87 -6 122
   -15 119 -6 -2 -24 -30 -41 -62 -27 -54 -30 -67 -30 -159 0 -93 2 -106 33 -171
   39 -83 15 -67 -42 28 -28 48 -35 55 -41 38 -14 -46 33 -166 92 -231 40 -45 36
   -50 -13 -19 -37 22 -42 14 -20 -29 21 -41 78 -91 132 -118 56 -27 48 -30 -21
   -9 -27 8 -51 13 -52 11 -10 -9 81 -76 123 -91 91 -32 93 -34 39 -34 -42 -1
   -47 -3 -35 -15 21 -19 76 -36 116 -36 42 0 44 -17 8 -59 l-26 -31 28 10 c16 6
   49 27 74 47 42 34 41 32 -14 -24 -38 -40 -54 -62 -45 -65 8 -3 -19 -16 -60
   -28 -59 -18 -87 -21 -139 -17 -60 6 -64 5 -45 -8 12 -8 45 -19 75 -25 51 -9
   50 -9 -21 -4 -40 2 -94 9 -118 15 -24 7 -46 9 -49 6 -14 -14 83 -49 154 -55
   l77 -8 -51 -7 c-27 -4 -74 -3 -104 3 -72 14 -75 5 -4 -16 44 -12 76 -15 138
   -10 45 4 92 7 106 7 14 0 41 5 60 12 19 6 41 10 48 8 8 -1 24 5 38 14 22 14
   24 14 24 -2 0 -11 33 -46 76 -82 55 -46 74 -67 69 -79 -11 -29 -10 -34 4 -40
   8 -3 11 0 7 7 -11 17 43 35 60 20 11 -9 14 -6 14 15 0 18 -7 29 -22 36 -21 8
   -21 9 8 9 16 1 35 -4 42 -11 8 -8 10 -4 8 18 -2 16 -4 31 -5 34 0 3 -13 6 -28
   5 -24 0 -28 5 -33 35 -3 19 -14 47 -24 62 -17 26 -17 28 4 75 11 26 47 74 78
   107 32 32 47 53 34 46 -13 -6 -26 -10 -28 -7 -8 7 110 55 154 62 31 5 49 3 67
   -9 13 -9 29 -16 35 -16 15 0 12 32 -5 54 -8 11 -15 28 -15 37 0 28 -56 67
   -106 75 -36 5 -62 1 -120 -18 -40 -14 -71 -20 -67 -14 24 39 -19 129 -117 246
   -39 47 -85 114 -103 150 -30 61 -32 72 -32 170 0 58 3 124 8 148 10 59 -6 54
   -53 -19z m559 -728 c8 -5 9 -11 3 -15 -5 -3 -15 1 -22 9 -14 16 -3 20 19 6z
   m-284 -471 c4 -6 1 -18 -6 -26 -12 -14 -14 -14 -26 2 -7 10 -13 26 -13 36 0
   15 3 17 19 8 11 -5 23 -15 26 -20z"/>
   <path stroke="#000000" stroke-width="20" d="M8134 1788 c4 -13 10 -64 13 -115 9 -152 -17 -222 -143 -380 -76 -95
   -103 -144 -119 -216 -4 -22 -10 -27 -23 -22 -85 37 -171 41 -218 11 -23 -16
   -74 -106 -74 -133 0 -16 22 -17 38 -1 20 20 108 11 172 -18 33 -15 58 -29 56
   -31 -2 -3 -15 1 -28 7 -13 7 2 -14 34 -46 88 -91 110 -158 68 -209 -4 -5 -10
   -23 -12 -40 -2 -24 -9 -31 -33 -35 -24 -4 -30 -10 -32 -34 -1 -22 1 -26 9 -18
   7 7 26 12 42 12 31 0 31 0 7 -13 -13 -6 -23 -15 -22 -19 0 -5 1 -15 1 -24 0
   -13 3 -14 14 -5 16 14 71 -3 61 -19 -3 -5 -2 -10 3 -10 17 0 21 13 10 33 -9
   18 -3 27 45 71 31 28 64 56 74 62 10 6 19 21 21 32 3 20 5 21 27 6 14 -9 30
   -14 37 -11 7 2 22 0 33 -6 46 -24 292 -30 343 -8 45 19 35 23 -28 11 -30 -6
   -77 -7 -104 -3 l-51 8 70 5 c62 6 175 43 164 55 -3 2 -41 -2 -84 -10 -44 -8
   -98 -14 -120 -14 -35 1 -33 2 23 13 89 18 101 35 19 29 -50 -4 -82 0 -138 17
   -41 13 -68 25 -60 28 9 3 -7 25 -45 65 -55 56 -56 58 -14 24 25 -20 58 -41 74
   -47 l28 -10 -26 31 c-33 39 -33 59 0 59 40 0 112 21 125 36 9 12 3 14 -35 14
   -54 0 -54 14 1 25 20 4 53 15 73 25 38 20 107 87 78 77 -41 -14 -100 -28 -105
   -24 -2 3 6 8 19 12 49 14 126 76 149 120 27 52 21 66 -17 36 -43 -34 -49 -24
   -11 18 20 22 42 52 50 68 25 48 47 135 41 159 -6 22 -9 19 -41 -33 -19 -32
   -43 -67 -54 -78 -17 -17 -16 -12 6 37 56 123 56 268 -1 365 -36 61 -48 56 -40
   -19 8 -74 -4 -150 -35 -233 -32 -88 -130 -181 -282 -270 -110 -64 -145 -93
   -190 -155 l-17 -25 14 31 c22 46 93 106 190 160 137 76 196 145 196 230 l0 37
   -36 -39 c-31 -35 -35 -36 -29 -14 15 63 16 89 5 128 -12 48 -24 48 -35 0 -7
   -31 -8 -29 -16 28 -9 73 -27 119 -70 179 -35 48 -56 59 -45 23z m-479 -768
   c-3 -5 -13 -10 -21 -10 -8 0 -12 5 -9 10 3 6 13 10 21 10 8 0 12 -4 9 -10z
   m295 -495 c-10 -12 -14 -12 -28 2 -15 14 -14 17 8 36 l25 19 3 -21 c2 -12 -2
   -28 -8 -36z"/>
   <path stroke="#000000" stroke-width="20" d="M3881 1605 c-11 -19 -27 -38 -36 -41 -22 -9 -19 -44 4 -44 17 0 19
   -10 22 -113 3 -77 8 -119 18 -130 17 -21 63 -22 101 -2 38 20 30 46 -14 42
   l-31 -2 -7 95 c-5 52 -6 100 -4 107 3 6 15 13 28 15 15 2 24 11 26 26 3 19 -1
   22 -27 22 -25 0 -30 4 -33 27 -5 39 -23 39 -47 -2z"/>
   <path stroke="#000000" stroke-width="20" d="M2952 1605 c-46 -7 -86 -15 -88 -18 -10 -10 10 -37 27 -37 10 0 19
   -3 19 -7 0 -5 11 -79 25 -166 29 -187 29 -177 10 -177 -9 0 -15 -10 -15 -26 0
   -22 4 -25 23 -21 12 3 43 9 70 12 51 8 60 15 51 39 -4 10 -13 14 -24 11 -14
   -4 -19 2 -25 31 -11 62 6 62 89 2 71 -51 79 -54 118 -49 34 5 43 10 43 26 0
   13 -9 21 -31 26 -49 9 -131 79 -95 79 20 0 71 63 81 101 21 78 -26 158 -105
   178 -52 13 -64 13 -173 -4z m178 -60 c11 -10 25 -35 30 -56 20 -73 -22 -127
   -112 -144 l-36 -7 -10 64 c-26 155 -26 156 -1 161 43 9 109 0 129 -18z"/>
   <path stroke="#000000" stroke-width="20" d="M4077 1593 c-3 -4 -8 -27 -12 -50 -6 -36 -4 -44 11 -50 11 -4 19 -2
   22 6 16 48 92 69 112 32 15 -29 12 -41 -10 -41 -49 0 -121 -23 -144 -47 -50
   -50 -23 -149 47 -172 29 -10 42 -9 73 4 26 11 40 12 50 4 7 -5 30 -9 51 -7 31
   2 39 7 41 26 3 16 -2 22 -16 22 -19 0 -21 8 -24 115 -3 102 -5 117 -25 136
   -21 22 -51 27 -113 19 -14 -1 -32 0 -41 4 -10 4 -19 4 -22 -1z m140 -200 c-5
   -50 -25 -73 -63 -73 -49 0 -72 64 -37 102 11 12 30 18 61 18 l45 0 -6 -47z"/>
   <path stroke="#000000" stroke-width="20" d="M4398 1583 c-36 -10 -48 -18 -48 -33 0 -13 7 -20 19 -20 18 0 19 -5
   14 -69 -7 -88 2 -137 32 -167 28 -28 81 -32 125 -9 25 13 29 13 34 0 4 -10 20
   -15 51 -15 38 0 45 3 45 20 0 10 -7 23 -16 28 -13 7 -15 28 -12 140 3 146 4
   144 -61 121 -23 -8 -31 -17 -31 -35 0 -17 4 -22 15 -17 14 5 15 -7 13 -83 l-3
   -89 -38 -19 c-31 -15 -44 -16 -63 -8 -23 11 -24 15 -26 140 l-3 129 -47 -14z"/>
   <path stroke="#000000" stroke-width="20" d="M4735 1554 c-22 -29 -25 -38 -14 -45 8 -5 17 -9 22 -9 4 0 7 -43 7
   -95 0 -88 -1 -95 -20 -95 -15 0 -20 -7 -20 -25 0 -24 2 -25 70 -25 63 0 70 2
   70 20 0 14 -7 20 -21 20 -21 0 -22 3 -15 90 4 50 9 97 13 106 6 15 27 15 63 0
   10 -4 23 6 39 29 13 20 22 38 19 41 -3 3 -28 7 -54 9 -35 3 -54 -1 -66 -12
   -17 -15 -20 -15 -39 5 -11 12 -21 22 -23 22 -2 0 -16 -16 -31 -36z"/>
   <path stroke="#000000" stroke-width="20" d="M3629 1541 c-52 -53 -29 -111 59 -149 58 -25 76 -44 67 -71 -12 -38
   -56 -36 -112 5 -20 15 -23 14 -38 -10 -20 -30 -13 -43 31 -62 43 -18 68 -18
   108 1 92 44 85 155 -11 191 -27 9 -57 25 -68 36 -18 17 -18 19 -2 35 17 18 20
   17 88 -11 10 -5 19 1 26 17 10 22 8 26 -13 36 -47 21 -103 14 -135 -18z"/>
   <path stroke="#000000" stroke-width="20" d="M4997 1525 c-15 -42 -15 -46 3 -55 14 -8 21 -5 33 14 31 47 107 41
   107 -9 0 -24 -3 -25 -55 -25 -67 0 -109 -20 -130 -60 -45 -87 30 -176 127
   -150 29 8 44 8 51 1 6 -6 28 -11 49 -11 31 0 38 4 38 20 0 11 -7 20 -16 20
   -12 0 -14 8 -10 43 16 132 17 156 2 190 -18 44 -45 59 -101 56 -25 -2 -47 0
   -51 4 -17 17 -33 4 -47 -38z m135 -160 c-4 -49 -26 -75 -62 -75 -39 0 -64 31
   -55 70 8 36 34 50 85 47 34 -2 35 -3 32 -42z"/>
   <path stroke="#000000" stroke-width="20" d="M3360 1528 c-50 -34 -72 -71 -78 -126 -6 -64 24 -133 70 -162 49 -31
   126 -23 176 17 20 17 38 38 40 47 5 26 -26 31 -51 8 -12 -11 -34 -23 -48 -27
   -33 -8 -94 17 -109 44 -12 23 -3 29 138 80 34 13 62 29 62 35 0 26 -43 78 -75
   92 -49 20 -87 18 -125 -8z m110 -53 l21 -22 -66 -27 c-36 -14 -70 -26 -75 -26
   -16 0 -12 43 6 68 31 45 76 47 114 7z"/>
   <path stroke="#000000" stroke-width="20" d="M5318 1513 c-22 -6 -24 -43 -2 -43 10 0 14 -6 10 -17 -3 -10 -11 -56
   -17 -102 -10 -64 -17 -85 -30 -88 -9 -3 -19 -13 -22 -23 -5 -20 0 -22 91 -35
   36 -6 42 -4 42 11 0 9 -7 22 -15 28 -14 10 -14 21 -2 100 8 50 21 92 28 97 8
   5 34 8 59 7 55 -4 57 -12 40 -134 -10 -72 -15 -84 -31 -84 -24 0 -26 -37 -1
   -43 9 -3 38 -8 65 -12 40 -5 47 -4 47 11 0 9 -7 22 -16 29 -15 10 -15 20 1
   111 16 87 16 104 4 129 -24 50 -86 66 -151 40 -13 -5 -18 -2 -18 9 0 16 -40
   20 -82 9z"/>
   <path stroke="#000000" stroke-width="20" d="M5729 1494 c-7 -8 -25 -22 -41 -30 -31 -16 -35 -31 -13 -48 13 -10
   12 -21 -6 -96 -27 -113 -23 -161 14 -178 35 -17 102 1 93 25 -3 8 -6 16 -6 17
   0 2 -11 1 -24 -1 -13 -3 -26 -1 -29 4 -3 5 3 54 14 108 10 55 19 102 19 104 0
   2 9 1 21 -2 29 -8 49 2 49 23 0 11 -10 20 -28 23 -25 5 -28 9 -24 36 5 35 -16
   43 -39 15z"/>
   <path stroke="#000000" stroke-width="20" d="M1304 820 c-98 -30 -174 -121 -174 -207 0 -89 45 -135 213 -218 160
   -79 190 -119 150 -202 -22 -48 -66 -73 -128 -73 -65 0 -123 28 -179 83 l-44
   45 -49 -50 -48 -49 40 -35 c132 -114 315 -123 429 -23 88 77 118 186 77 273
   -29 59 -76 95 -202 155 -57 26 -116 60 -131 74 -48 45 -32 99 39 136 52 27
   143 4 211 -54 30 -25 34 -26 51 -11 10 9 32 36 49 60 l32 45 -28 20 c-25 18
   -30 18 -43 5 -14 -13 -21 -12 -64 7 -55 24 -154 33 -201 19z"/>
   <path stroke="#000000" stroke-width="20" d="M2000 819 c-124 -24 -247 -124 -291 -237 -33 -83 -33 -233 0 -315 44
   -111 161 -208 282 -236 101 -24 226 -8 318 38 61 32 138 112 166 174 147 324
   -118 646 -475 576z m208 -98 c85 -43 149 -134 172 -245 16 -78 6 -151 -31
   -226 -54 -106 -145 -157 -265 -147 -106 8 -189 72 -243 187 -23 48 -26 68 -26
   155 0 109 16 157 74 218 75 81 221 108 319 58z"/>
   <path stroke="#000000" stroke-width="20" d="M3493 815 c-126 -34 -215 -106 -267 -215 -29 -61 -31 -72 -31 -180 0
   -107 2 -119 29 -174 74 -150 234 -235 421 -224 117 8 186 41 270 129 55 58 68
   79 86 140 30 99 24 219 -15 304 -81 177 -296 273 -493 220z m223 -93 c121 -63
   196 -226 173 -376 -12 -71 -30 -109 -78 -159 -59 -63 -104 -82 -196 -82 -63 0
   -89 5 -120 22 -53 28 -121 103 -148 161 -29 65 -35 202 -13 270 29 88 107 159
   201 183 48 13 138 3 181 -19z"/>
   <path stroke="#000000" stroke-width="20" d="M4930 814 c-120 -32 -226 -126 -270 -241 -26 -66 -32 -200 -12 -268
   31 -109 128 -212 239 -256 48 -20 77 -23 169 -24 107 0 112 1 186 38 245 121
   304 439 119 640 -99 107 -275 152 -431 111z m198 -79 c66 -20 138 -89 178
   -170 26 -54 29 -70 29 -155 0 -84 -3 -102 -28 -152 -52 -106 -152 -165 -265
   -156 -108 8 -190 70 -242 180 -90 192 -17 398 162 453 58 18 104 18 166 0z"/>
   <path stroke="#000000" stroke-width="20" d="M2570 775 c0 -45 0 -45 35 -45 l35 0 0 -305 0 -305 -35 0 c-35 0 -35
   0 -35 -45 l0 -45 130 0 130 0 0 45 c0 45 0 45 -35 45 l-35 0 0 118 c0 118 0
   118 30 147 16 16 31 27 33 24 10 -11 167 -278 167 -282 0 -3 -10 -7 -22 -9
   -20 -3 -23 -9 -23 -43 l0 -40 133 -3 132 -3 0 45 c0 44 0 45 -37 48 -37 3 -39
   5 -150 188 -62 102 -112 190 -110 197 1 6 46 59 100 117 81 87 104 106 127
   106 32 0 46 25 36 66 -7 27 -30 30 -158 17 l-68 -6 0 -38 c0 -29 4 -38 19 -41
   15 -2 -6 -29 -95 -123 l-114 -120 0 123 0 122 35 0 c35 0 35 0 35 45 l0 45
   -130 0 -130 0 0 -45z"/>
   <path stroke="#000000" stroke-width="20" d="M4143 813 l-63 -4 0 -39 c0 -39 1 -40 35 -40 l35 0 0 -305 0 -305
   -35 0 c-35 0 -35 0 -35 -45 l0 -45 195 0 c170 0 195 -2 195 -16 0 -11 9 -14
   37 -12 l37 3 37 120 c20 66 34 123 31 127 -4 4 -19 9 -33 13 -22 5 -28 2 -38
   -23 -18 -44 -81 -99 -126 -111 -22 -6 -64 -11 -92 -11 l-53 0 0 305 0 305 40
   0 40 0 0 45 0 45 -72 -2 c-40 -1 -101 -3 -135 -5z"/>
   <path stroke="#000000" stroke-width="20" d="M5434 806 c-3 -8 -4 -27 -2 -43 2 -23 9 -29 36 -33 l34 -5 109 -305
   c59 -168 113 -324 119 -347 l11 -43 63 0 64 0 11 48 c17 66 230 662 247 689
   23 37 -1 45 -158 49 -169 4 -199 -5 -277 -87 -28 -30 -51 -48 -51 -42 0 6 -3
   18 -6 27 -5 12 0 16 21 16 25 0 26 2 23 43 l-3 42 -118 3 c-95 2 -118 0 -123
   -12z m524 -110 c24 -1 41 -5 39 -11 -3 -5 -42 -117 -88 -247 -46 -131 -86
   -238 -89 -238 -3 0 -34 82 -69 183 -35 100 -66 187 -68 193 -5 14 49 81 90
   111 29 22 38 24 87 17 30 -4 74 -8 98 -8z"/>
   <path stroke="#000000" stroke-width="20" d="M6160 775 c0 -45 0 -45 35 -45 l35 0 0 -305 0 -305 -35 0 c-35 0 -35
   0 -35 -45 l0 -45 69 0 c37 0 89 3 115 6 47 7 47 7 44 43 -3 33 -6 36 -35 39
   l-33 3 0 232 c0 195 2 229 14 217 7 -8 58 -87 113 -175 251 -402 223 -365 275
   -365 l38 0 0 350 0 350 35 0 c35 0 35 0 35 45 l0 46 -112 -3 -113 -3 0 -40 c0
   -38 2 -40 33 -43 l32 -3 0 -232 0 -232 -23 35 c-12 19 -90 142 -173 275 l-151
   240 -82 3 -81 3 0 -46z"/>
   <path stroke="#000000" stroke-width="20" d="M7133 803 c-4 -10 -62 -166 -128 -348 -118 -323 -121 -330 -150 -335
   -27 -5 -30 -9 -30 -45 l0 -40 103 -3 102 -3 0 46 c0 42 -2 45 -26 45 -28 0
   -28 -1 8 98 l19 52 143 0 143 0 23 -62 c13 -35 25 -69 27 -75 4 -9 -5 -13 -26
   -13 -30 0 -31 -1 -31 -45 l0 -46 128 3 127 3 0 40 c0 38 -2 40 -32 43 -30 3
   -32 7 -69 110 -21 59 -76 215 -123 347 l-86 240 -58 3 c-48 3 -58 0 -64 -15z
   m101 -292 c25 -73 46 -140 46 -147 0 -11 -23 -14 -105 -14 -77 0 -105 3 -105
   13 -1 25 104 297 111 290 4 -4 28 -68 53 -142z"/>
   </g>
   </svg>);
}