import carpacio from '../../assets/images/drinks/carpacio.jpg';
import dezert from '../../assets/images/foods/dezert.jpg';
import hranolky from '../../assets/images/foods/hranolky.jpg';
import kPivu from '../../assets/images/foods/kPivu.jpg';
import polevky from '../../assets/images/foods/poklevka.jpg';
import salaty from '../../assets/images/foods/salat.jpg';
import sauce from '../../assets/images/foods/sauce.jpg';
import speciality from '../../assets/images/foods/speciality.jpg';
import steak from '../../assets/images/landing-photos/XT036147.jpg';
import photoBackground from '../../assets/images/landing-photos/XT036170.jpg';
import Footer from '../../global-components/Footer';
import Header from '../../global-components/Header';
import MenuItem from '../../global-components/menu-item';
import PageHeader from '../../global-components/PageHeader';
import './foods.css';

import { useEffect } from 'react';
import ryby from '../../assets/images/foods/ryba.jpg';
import foods from './foodsJSON';

import $ from 'jquery';

export default function Drinks() {
  const createArray = (beverageJsonArray: any[]) => {
    return beverageJsonArray.map(
      (val: {
        name: string | number | boolean | null | undefined;
        price: string | number | boolean | null | undefined;
        description: string | number | boolean | null | undefined;
        imageSRC: string | undefined;
      }) => {
        return (
          <MenuItem
            itemName={val.name}
            itemPrice={val.price}
            description={val.description}
            imageSrc={val.imageSRC}
          />
        );
      }
    );
  };

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      $('#float-button').removeClass('d-none');
    } else {
      $('#float-button').addClass('d-none');
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      scrollFunction();
    };
  }, []);

  return (
    <>
      <a href='#' id='float-button' className='float d-none'>
        <i className='fas fa-arrow-up my-float'></i>
      </a>
      <Header position='top-fixed' />
      <div id='foods'>
        <PageHeader
          customID='foodsBackground'
          backgroundImage={photoBackground}
          title='Jídla'
        />
      </div>
      <div className='w-100 container-fluid button-row '>
        <div className='reporting-nav mt-5'>
          <div className='reporting-button-menu mx-auto'>
            <a href='#hotovky' className='reporting-button'>
              {' '}
              Hotová Jídla
            </a>
            <a href='#predkrm' className='reporting-button'>
              Předkrmy
            </a>
            <a href='#polevky' className='reporting-button'>
              Polévky
            </a>
            <a href='#steak' className='reporting-button'>
              Steak
            </a>
            <a href='#speciality' className='reporting-button'>
              Speciality
            </a>
            <a href='#testoviny' className='reporting-button'>
              Těstoviny, Noky
            </a>
          </div>
        </div>
        <div className='reporting-nav mt-5'>
          <div className='reporting-button-menu mx-auto'>
            <a href='#toBeer' className='reporting-button'>
              Něco k pivu
            </a>
            <a href='#ryby' className='reporting-button'>
              Ryby
            </a>
            <a href='#salaty' className='reporting-button'>
              Saláty
            </a>
            <a href='#prilohy' className='reporting-button'>
              Přílohy
            </a>
            <a href='#omacky' className='reporting-button'>
              Omáčky
            </a>
            <a href='#dezerty' className='reporting-button'>
              Dezerty
            </a>
          </div>
        </div>
      </div>

      <div id='hotovky' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Nabídka hotových jídel</h1>
          {createArray(foods.prepFood)}
        </div>
      </div>
      <div id='predkrm'>
        <PageHeader
          customID='predkrmBackground'
          backgroundImage={carpacio}
          title='Předkrmy'
        />
      </div>
      <div id='predkmArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Předkrmy</h1>
          {createArray(foods.predkrmy)}
        </div>
      </div>

      <div id='polevky'>
        <PageHeader
          customID='polevkyBackground'
          backgroundImage={polevky}
          title='Polévky'
        />
      </div>
      <div id='polevkyArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Polévky</h1>
          {createArray(foods.polevky)}
        </div>
      </div>
      <div id='steak'>
        <PageHeader
          customID='steakBackground'
          backgroundImage={steak}
          title='Steak'
        />
      </div>
      <div id='steakArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Steak</h1>
          {createArray(foods.steak)}
        </div>
      </div>

      <div id='speciality'>
        <PageHeader
          customID='specialityBackground'
          backgroundImage={speciality}
          title='Speciality'
        />
      </div>
      <div id='specialityArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Speciality</h1>
          {createArray(foods.speciality)}
        </div>
      </div>

      <div id='testoviny'>
        <PageHeader
          customID='testovinyBackground'
          backgroundImage={photoBackground}
          title='Těstoviny'
        />
      </div>
      <div id='testovinyArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Těstoviny</h1>
          {createArray(foods.testoviny)}
        </div>
      </div>

      <div id='toBeer'>
        <PageHeader
          customID='toBeerBackground'
          backgroundImage={kPivu}
          title='Něco k Pivu'
        />
      </div>
      <div id='toBeerArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Něco k Pivu</h1>
          {createArray(foods.kPivu)}
        </div>
      </div>

      <div id='ryby'>
        <PageHeader
          customID='rybyBackground'
          backgroundImage={ryby}
          title='Ryby'
        />
      </div>
      <div id='toBeerArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Ryby</h1>
          {createArray(foods.ryby)}
        </div>
      </div>
      <div id='salaty'>
        <PageHeader
          customID='salatyBackground'
          backgroundImage={salaty}
          title='Saláty'
        />
      </div>
      <div id='salatyArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Saláty</h1>
          {createArray(foods.salaty)}
        </div>
      </div>

      <div id='prilohy'>
        <PageHeader
          customID='prilohyBackground'
          backgroundImage={hranolky}
          title='Přílohy'
        />
      </div>
      <div id='prilohyArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Přílohy</h1>
          {createArray(foods.prilohy)}
        </div>
      </div>

      <div id='omacky'>
        <PageHeader
          customID='omackyBackground'
          backgroundImage={sauce}
          title='Omáčky'
        />
      </div>
      <div id='omackyArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Omáčky</h1>
          {createArray(foods.omacky)}
        </div>
      </div>
      <div id='dezerty'>
        <PageHeader
          customID='dezertyBackground'
          backgroundImage={dezert}
          title='Dezerty'
        />
      </div>
      <div id='dezertyArray' className='pb-5 apperitive'>
        <div className='col-12 mx-auto list-parrent col-md-6 col-sm-8 col-lg-5'>
          <h1 className='list-header mx-auto'>Dezerty</h1>
          {createArray(foods.dezerty)}
        </div>
      </div>

      <Footer />
    </>
  );
}
