import { APIprovider } from './Request-service';

export class APISpecialOfferProvider {
  private apiProvider = new APIprovider();

  fetchSpecialOffer(): Promise<Response> {
    return this.apiProvider.get('/specialOffer');
  }

  deleteSpecialOffer(id: number): Promise<Response> {
    return this.apiProvider.get('/deleteSpecialOffer?id=' + id);
  }

  uploadSpecialOffer(
    name: string,
    price: string,
    description: string,
    image_data: string
  ): Promise<Response> {
    const uploadData = {
      name: name,
      price: price,
      description: description,
      image_data: image_data,
    };

    return this.apiProvider.post(
      '/uploadSpecialOffer',
      JSON.stringify(uploadData)
    );
  }
}
