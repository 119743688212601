
import { Navbar, Nav, Container } from 'react-bootstrap';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DameJidloIcon from '../assets/logos/dame-jidlo-icon';
import PhoneIcon from '@mui/icons-material/Phone';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Logo from '../assets/logos/logo';
import $ from 'jquery';

import './components.css';
import { useEffect } from 'react';
import React from 'react';

export default function Header(props: {
  position: string;
  specialOffer?: any;
}) {
  useEffect(() => {
    if (props.position === 'top-animate') {
      $('#main-logo-div').removeClass('logo-div-away');
      $('#main-logo-div').addClass('logo-div-landing');
      window.onscroll = () => scrollFunction();
      scrollFunction();
    } else if (props.position === 'top-fixed') {
      $('#navbar-custom').addClass('navbar-away');
      $('#main-logo-div').removeClass('logo-div-landing');
      $('#main-logo-div').addClass('logo-div-away');
    }
  }, []);

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      $('#main-logo-div').removeClass('logo-div-landing');
      $('#main-logo-div').addClass('logo-div-away');
      $('#navbar-custom').removeClass('navbar-landing');
      $('#navbar-custom').addClass('navbar-away');
    } else {
      $('#main-logo-div').removeClass('logo-div-away');
      $('#main-logo-div').addClass('logo-div-landing');
      $('#navbar-custom').removeClass('navbar-away');
      $('#navbar-custom').addClass('navbar-landing');
    }
  };

  const specialOffer = props.specialOffer ? (
    <div className='specialOffer text-center'>
      <h2>
        {' '}
        <a href='#special-offer-item'>🍔🍹❗️Speciální nabídka❗️🍹🍔</a>
      </h2>
    </div>
  ) : (
    <></>
  );
  return (
    <>
      {specialOffer}
      <Navbar
        id='navbar-custom'
        collapseOnSelect
        className='mx-auto'
        expand='xl'
        variant='dark'
      >
        <Container fluid>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav mx-auto'>
            <Nav className='mx-auto'>
              <Nav.Link href='/drinks' className='hover-underline-animation'>
                <LocalBarIcon />
                <br />
                Nápoje
              </Nav.Link>
              <Nav.Link href='/foods' className='hover-underline-animation'>
                <RestaurantIcon />
                <br />
                Jídla
              </Nav.Link>
              <Nav.Link href='/coupons' className='hover-underline-animation'>
                <CardGiftcardIcon />
                <br />
                Kupóny
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <a id='main-logo-div' className='mx-auto logo-div-away' href='/'>
            <Logo />
          </a>
          <Navbar.Collapse className='mx-auto' id='responsive-navbar-nav'>
            <Nav className='mx-auto'>
              <Nav.Link href='/contact' className='hover-underline-animation'>
                <PhoneIcon />
                <br />
                Kontakt
              </Nav.Link>
              <Nav.Link
                href='https://www.damejidlo.cz/restaurant/t4gv/restaurace-sokolovna-t4gv'
                className='hover-underline-animation dame-jidlo'
              >
                <DameJidloIcon />
                <br />
                Dáme Jídlo
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
