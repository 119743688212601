import React from 'react';

export default function MenuItem(props: {
  imageSrc?: string | undefined;
  itemName: string | number | boolean | null | undefined;
  itemPrice: string | number | boolean | null | undefined;
  description: string | number | boolean | null | undefined;
}) {
  console.log(props);
  const image = () => {
    if (props.imageSrc === undefined) return;
    return <img width='100%' src={props.imageSrc} alt='' />;
  };
  return (
    <div className='w-100'>
      <div className='food-menu-item'>
        <div className='food-menu-main'>
          <div className='food-menu-header'>
            <div className='pt-food-menu-title'>
              <span className='title-wrap'>
                <p>{props.itemName}</p>
              </span>
            </div>
            <div className='pt-food-menu-lines'></div>
            <div className='pt-food-menu-price'>{props.itemPrice}</div>
          </div>
          <div className='food-menu-details'>
            <p>{props.description}</p>
          </div>
          <div
            className={
              props.imageSrc === undefined || props.imageSrc === ''
                ? 'food-image-container text-center w-100 d-none'
                : 'food-image-container text-center w-100'
            }
          >
            {image()}
          </div>
        </div>
      </div>
    </div>
  );
}
