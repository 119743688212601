import './Login.css';
import $ from 'jquery';
import Logo from '../../assets/logos/logo';
import { UserManager } from '../../providers/local-providers/UserManager';
import { UserStorage } from '../../providers/local-providers/UserStorage';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const userManager: UserManager = new UserManager();
const userStorage: UserStorage = new UserStorage();

export default function Login() {
  const [loginNumber, setLoginNumber] = useState('');
  const [errorText, setErrorText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (userStorage.getUsertoken().toString() != '') {
      navigate('../administration');
    }
  }, []);

  const submitForm = (): void => {
    if (loginNumber != '') {
      setErrorText('');
      userManager.login(loginNumber as string).then((data) => {
        if (data) {
          navigate('../administration');
        } else {
          setErrorText('špatné přihlašovací údaje');
        }
      });
    } else {
      setErrorText('pole "Uživatelské číslo" nesmí být prázdné!');
    }
  };

  return (
    <>
      <div id='login'>
        <div className='global-container'>
          <div className='card login-form'>
            <div className='card-body text-center'>
              <a className='navbar-brand mb-5' href='../..'>
                <Logo />
              </a>
              <div className='card-text'>
                <div className='form-group'>
                  <div className='mx-auto text-center mb-3 login-title'>
                    Přihlásit se
                  </div>
                  <div className=' w-100 text-left login-input-description'>
                    Uživatelské číslo
                  </div>
                  <input
                    type='password'
                    className='form-control login-number-input form-control-sm'
                    name='passwrod'
                    id='password-input'
                    value={loginNumber}
                    onChange={(e) => setLoginNumber(e.target.value)}
                    required
                  ></input>
                  <br />
                  <div className='error-text w-100 text-center'>
                    <p>{errorText}</p>
                  </div>
                </div>
                <button
                  onClick={() => {
                    submitForm();
                  }}
                  className='btn w-25 btn-primary submit-button mx-auto mt-5 mb-5 btn-block'
                >
                  Přihlásit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
