import Header from '../../global-components/Header';
import './home.css';
import 'react-slideshow-image/dist/styles.css';
import Footer from '../../global-components/Footer';
import photo1 from '../../assets/images/landing-photos/_25A2432.jpg';
import photo2 from '../../assets/images/landing-photos/_25A2449.jpg';
import photo3 from '../../assets/images/landing-photos/_25A2454.jpg';
import photo4 from '../../assets/images/landing-photos/_25A2476.jpg';
import photo5 from '../../assets/images/landing-photos/_25A2487.jpg';
import photo6 from '../../assets/images/landing-photos/_25A2553.jpg';
import photo7 from '../../assets/images/landing-photos/_25A2560.jpg';
import photo8 from '../../assets/images/landing-photos/_25A2564.jpg';
import photo10 from '../../assets/images/_25A2537.jpg';
import photo11 from '../../assets/images/IMG_4321.jpeg';
import photo12 from '../../assets/images/IMG_4326.jpeg';

import photo9 from '../../assets/images/landing-photos/_25A2570.jpg';

import photoRepre1 from '../../assets/images/IMG_6908.jpg';
import photoRepre2 from '../../assets/images/IMG_6956.jpg';
import photoRepre3 from '../../assets/images/IMG_7023.jpg';
import photoRepre4 from '../../assets/images/landing-photos/_25A2449.jpg';
import photoRepre5 from '../../assets/images/landing-photos/_25A2560.jpg';
import photoRepre6 from '../../assets/images/landing-photos/XT036125.jpg';
import photoRepre8 from '../../assets/images/IMG_6900.jpg';

import LocalBarIcon from '@mui/icons-material/LocalBar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CollectionsIcon from '@mui/icons-material/Collections';
import SimpleSlider from '../../global-components/sponsor-carousel';
import MenuItem from '../../global-components/menu-item';
import { APIWeekOfferProvider } from '../../providers/API-providers/api-week-offer-provider';
import SimpleImageSlider from 'react-simple-image-slider';
import ImageViewer from 'react-simple-image-viewer';
import { APISpecialOfferProvider } from '../../providers/API-providers/api-special-offer-provider';
import $ from 'jquery';
import {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  useEffect,
  useState,
  useCallback,
} from 'react';
import Aos from 'aos';

var drinksItems:
  | string
  | number
  | boolean
  | JSX.Element[]
  | ReactElement<any, string | JSXElementConstructor<any>>
  | ReactFragment
  | null
  | undefined = [];
var foodItems:
  | string
  | number
  | boolean
  | ReactFragment
  | JSX.Element[]
  | ReactElement<any, string | JSXElementConstructor<any>>
  | null
  | undefined = [];

const picturesSlider = [
  { url: photoRepre1 },
  { url: photo6 },
  { url: photoRepre2 },
  { url: photo10 },
  { url: photo11 },
  { url: photo12 },
  { url: photoRepre3 },
  { url: photoRepre5 },
  { url: photoRepre6 },
  { url: photoRepre8 },
];

var imageArray: [string];

const favouriteDrinks = [
  {
    name: 'Mojito',
    price: '125 Kč',
    description: '(Havana rum, limetka, máta, třtinový cukr, lime juice, soda)',
    imageSRC: '',
  },
  {
    name: 'Aperol Spritz',
    price: '115 Kč',
    description: '(Aperol, Prosecco, soda, pomeranč)',
    imageSRC: '',
  },
  {
    name: 'B-52',
    price: '115 Kč',
    description: '(tequila, grenadina, pomerančový juice)',
    imageSRC: '',
  },
  {
    name: 'Gin Tonic',
    price: '115 Kč',
    description: '(Beefeater, tonic, citrón)',
    imageSRC: '',
  },
];
const favouriteFoods = [
  {
    name: 'Vídeňský hovězí guláš, HK (1, 3, 6)',
    price: '195 Kč',
    description: '150g',
    imageSRC: '',
  },
  {
    name: 'svíčková na smetaně, HK (1, 3, 7, 9, 10)',
    price: '195 Kč',
    description: '150g',
    imageSRC: '',
  },
  {
    name: 'Quesadilla',
    price: '310 Kč',
    description: 'Quesadilla s kuřecím masem a variacemi 3 omáček.',
  },
  {
    name: 'Špagetti aglio olio',
    price: '195 Kč',
    description: 'Špagety, česnek, chilli',
  },
];
const images = [  
  photo6,
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo7,
  photo10,
  photo9,
];

const apiWeekOfferProvider: APIWeekOfferProvider = new APIWeekOfferProvider();
const apiSpecialOfferProvider = new APISpecialOfferProvider();

export default function Home() {
  const [backgroundImage, setBackgroundImage] = useState(photo6);
  var isDataFilled = false;
  const [weekOfferData, setWeekOfferData] = useState({
    imageData: '',
    createAt: new Date(),
    removeAt: new Date(),
  });
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [specialOfferData, setSpecialOfferData] = useState([
    {
      id: 0,
      title: '',
      price: '',
      description: '',
      imageData: '',
    },
  ]);
  useEffect(() => {
    drinksItems = favouriteDrinks.map((val) => {
      return (
        <MenuItem
          itemName={val.name}
          itemPrice={val.price}
          description={val.description}
        />
      );
    });
    foodItems = favouriteFoods.map((val) => {
      return (
        <MenuItem
          itemName={val.name}
          itemPrice={val.price}
          description={val.description}
        />
      );
    });
    fetchWeekOffer();
    fetchSpecialOffer();
    setInterval(() => {
      fetchWeekOffer();
    }, 15000);
    var imagesIndex = 0;
    Aos.init();
    setInterval(() => {
      // $('#landing').css('background-image','url("'+images[imagesIndex] +'")');
      setBackgroundImage(images[imagesIndex]);
      imagesIndex++;
      if (imagesIndex == 9) imagesIndex = 0;
    }, 5000);
  }, []);

  useEffect(() => {
    $('#landing').css('background-image', 'url(' + backgroundImage + ')');
  }, [backgroundImage]);

  const fetchWeekOffer = () => {
    apiWeekOfferProvider
      .fetchWeekOffer()
      .then((response) => {
        if (response.status == 204) {
          return 204;
        }
        if (response.ok) {
          return response.json();
        } else {
          //toast error
          return false;
        }
      })
      .then((data) => {
        if (data == 204) {
          clearData();
          isDataFilled = false;
        } else {
          setWeekOfferData({
            imageData: data.weekOffer?.image_data,
            createAt: new Date(data.weekOffer?.create_at),
            removeAt: new Date(data.weekOffer?.remove_at),
          });
          isDataFilled = true;
        }
      })
      .catch((error) => {
        //toast error
        clearData();
        console.error(error);
      });
  };

  const fetchSpecialOffer = () => {
    apiSpecialOfferProvider
      .fetchSpecialOffer()
      .then((response) => {
        if (response.status == 204) {
          return 204;
        }
        if (response.ok) {
          return response.json();
        } else {
          //toast error
          return false;
        }
      })
      .then((data: any) => {
        if (!data) {
          throw 'error something went wrong';
        }

        if (data == 204) {
          clearSpecialOffer();
        } else {
          setSpecialOfferData([]);
          setSpecialOfferData((specialOfferData) =>
            data.specialOfferArray?.map((element: any) => {
              return {
                id: 0,
                title: element.name,
                price: element.price,
                description: element.description,
                imageData: element.image_data,
              };
            })
          );
          if (data.specialOfferArray.length > 0)
            $('#specialOfferModalToggler').trigger('click');
        }
      })
      .catch((error) => {
        clearSpecialOffer();
        console.error(error);
      });
  };

  const clearSpecialOffer = () => {
    setSpecialOfferData((specialOfferData) => [
      {
        id: 0,
        title: '',
        price: '',
        description: '',
        imageData: '',
      },
    ]);
  };

  const clearData = () => {
    isDataFilled = false;
    setWeekOfferData({
      imageData: '',
      createAt: new Date(),
      removeAt: new Date(),
    });
  };

  const openImage = useCallback(() => {
    setIsViewerOpen(true);
  }, []);

  const closeImage = useCallback(() => {
    setIsViewerOpen(false);
  }, []);

  const getWeekOfferElement = () => {
    let createAt = new Date(weekOfferData.createAt);
    let removeAt = new Date(weekOfferData.removeAt);
    let now = new Date();
    if (weekOfferData.imageData != '' && now >= createAt && now <= removeAt) {
      imageArray = [weekOfferData.imageData];
      return (
        <div className='special-offer container-fluid'>
          <div className='row  mx-auto align-items-center' id='week-offer'>
            <div className='col-12 mx-auto col-lg-6 col-md-8 col-sm-10'>
              <div
                data-aos='zoom-out'
                data-aos-easing='ease-in-back'
                data-aos-duration='400'
                className='card card-offer-menu'
              >
                <div className='card-body '>
                  <h2 className='card-title'>Týdenní nabídka</h2>
                  <img
                    src={weekOfferData.imageData}
                    alt=''
                    onClick={() => openImage()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const mapSpecialOffer = () => {
    return specialOfferData.map((element, key) => {
      return (
        <>
          <MenuItem
            itemName={element.title}
            itemPrice={element.price}
            description={element.description}
            imageSrc={element.imageData}
            key={key}
          />
        </>
      );
    });
  };

  const getSpecialOfferModal = () => {
    if (specialOfferData.length > 0) {
      return (
        <div
          className='modal  fade'
          id='specialOfferModal'
          role='dialog'
          aria-labelledby='specialOfferModal'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content special-offer-modal custom-modal'>
              <div className='modal-header'>
                <h5 className='modal-title' id='specialOfferModalTitle'>
                  Speciální nabídka
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  id='close-button'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='modal-special-offer'>
                  <div
                    className='col-12 mx-auto mt-5 col-md-12'
                    id='special-offer-item'
                  >
                    <div
                      data-aos='zoom-out'
                      data-aos-easing='ease-in-back'
                      data-aos-duration='400'
                      className='card card-offer-menu'
                    >
                      <div className='card-body '>
                        <h2 className='card-title'>Speciální nabídka</h2>
                        {mapSpecialOffer()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {isViewerOpen && (
        <ImageViewer
          src={imageArray}
          currentIndex={0}
          onClose={closeImage}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
      {getSpecialOfferModal()}

      <div className='h-100'>
        <Header position='top-animate' specialOffer={false} />
        <div className='parallax' id='landing'>
          <p>Vyberte si z naší široké nabídky jídel a nápojů.</p>
          <a
            type='button'
            href='/drinks'
            className='btn ml-1 mr-1 btn-outline-light'
          >
            <LocalBarIcon />
          </a>
          <a
            type='button'
            href='/foods'
            className='btn ml-1 mr-1 btn-outline-light'
          >
            <RestaurantIcon />
          </a>
        </div>
      </div>
      {getWeekOfferElement()}
      <SimpleSlider />
      <div className='col-12 col-md-12'>
        <button
          type='button'
          className='btn btn-primary d-none'
          data-toggle='modal'
          data-target='#specialOfferModal'
          id='specialOfferModalToggler'
        ></button>
      </div>

      <div id='basic-offer-menu' className='container'>
        <div className='row w-100 mx-auto align-items-center'>
          <div className='col-12 mx-auto col-md-6'>
            <div
              data-aos='zoom-out'
              data-aos-easing='ease-in-back'
              data-aos-duration='400'
              className='card card-offer-menu'
            >
              <div className='card-body '>
                <h2 className='card-title'>Oblíbená jídla</h2>
                {foodItems}
              </div>
              <div className='row mx-auto'>
                <div className='col text-center'>
                  <a href='/foods' className='btn btn-primary refer-btn'>
                    Jídelní lístek
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 mx-auto col-md-6'>
            <div
              data-aos='zoom-out'
              data-aos-easing='ease-in-back'
              data-aos-duration='400'
              className='card card-offer-menu'
            >
              <div className='card-body '>
                <h2 className='card-title'>Oblíbené drinky</h2>
                {drinksItems}
              </div>
              <div className='row mx-auto'>
                <div className='col text-center'>
                  <a href='/drinks' className='btn btn-primary refer-btn'>
                    Nápojový lístek
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='firstLookGallery w-100'>
        <div className='photo-preview-title text-center w-100'>
          <h1>Nahlédněte k nám</h1>
          <h3>jak to u nás vypadá.</h3>
          <div className='image-slider mx-auto'>
            <SimpleImageSlider
              width={'60%'}
              height={'70%'}
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              images={picturesSlider}
              showBullets={true}
              showNavs={true}
              slideDuration={0.6}
              autoPlay={true}
              autoPlayDelay={7.5}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
